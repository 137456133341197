
import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { useNavigate,Link } from 'react-router-dom';

import { RoutesPaths } from "../../routes";
import ClientSession  from "../../services/client-session";
import LModel from "../../services/api";

import BgImage from "../../assets/img/illustrations/signin.svg";



export default () => {
  // const history = useHistory();
  const navigate = useNavigate();

  const [username,setUsername]= useState("");
  const [email,setEmail]= useState("");
  const [password,setPassword]= useState("");
  const [confirmPassword,setConfirmPassword]= useState("");
  // const [username,setUsername]= useState("");

  useEffect(() => {
    authUser();
  }, []);
  
 
  const authUser=()=>{
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if(isLoggedIn){
        // Redirect to Home
          navigate("/");
      }else console.log(isLoggedIn,authData);
    });
  }


const performRegister = (e) =>{
  e.preventDefault();
  if(password=="" || confirmPassword=="" || email=="" ){
    return alert("Please Fill All Fields");
  }
  else{
    var data = {username:username,email:email,password:password}
    LModel.createNoToken(`register/`,data,null)
      .then( response => {
        if(response.data){
          // this.setState({visibleRegister:false});
          alert("Registeration Sucessfull");
          // history.push("/");
          navigate("/");
        }
      })
      .catch(err => {
        if(!err.response.data){
        return alert("Error While Loading Data");
      }
        alert(err.response.data[Object.keys(err.response.data)[0]],5);
      });
  }
}

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={RoutesPaths.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form className="mt-4">
                <Form.Group id="email" className="mb-4">
                    <Form.Label>User Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus type="text" placeholder="Your Name" onChange={(e)=>setUsername(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="text" placeholder="example@company.com" onChange={(e)=>setEmail(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Confirm Password" onChange={(e)=>setConfirmPassword(e.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  {/* <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck> */}

                  <Button variant="primary" type="submit" onClick={(e)=>performRegister(e)} className="w-100">
                    Sign up
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={RoutesPaths.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
