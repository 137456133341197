import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card,Table, Image } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation } from 'react-router-dom';

import { Spin, Modal, message } from 'antd';

import { LoadingOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit,faList,faDollarSign,faTrash } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const { confirm } = Modal;
export default () => {
  const [mainconfigs, setMainConfigs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [configOptions, setConfigOptions] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    setLoading(true);
    getCongigOptions();
    getJackpotCongigurations();
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };
  const getJackpotCongigurations = () => {
    LModel.find("octagon/superjackpot/jackpot/").then((response) => {
      if (response.data) {
        setMainConfigs(response.data);
        console.log(response.data);

        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }
  const deleteJackpot1 = (id) => {

    LModel.destroy(`octagon/superjackpot/jackpot`,id,null).then((response) => {
      if (response.data) {
        getJackpotCongigurations();
        setMainConfigs({});
        // params.id && setPrices([response.data]);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err.response.data);
      if(err.response.status==405)
        message.error("Delete Not Allowed");
      // setLoading(false);
    });
  }
  const deleteJackpot = (id) => {
    confirm({
      title: 'Do you want to delete Jackpot item?',
      icon: <ExclamationCircleOutlined />,
      content: `Jackpot Item will be Deleted Permanently!`,
      onOk() {
        setLoading(true);
        return new Promise((resolve, reject) => {
          LModel.destroy(`octagon/superjackpot/jackpot`,id).then(response => {
          console.log(response);
          getJackpotCongigurations();
          setLoading(false);
          resolve()
        }).catch(err => {
          console.log(err);
          if(err.response.status==405)
            message.error("Delete Not Allowed");
          setLoading(false);
          reject(err);
        });
      }).catch((err) => console.log('Oops errors!',err));
    },
    onCancel() {},
  });

  }

  const  createNewJackpot =()=>{

  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }


  const handleCancel = () => setPreviewVisible(false);

  const TableRow = (props) => {
    const { id, title, description, banner, stake, status, prizes,event_choices,num } = props;
    const stats= configOptions.superjackpot_status && configOptions.superjackpot_status.find((option, idx) =>option[0] == status);
    return (
      <tr>
        <td>
          <span className="text-primary fw-bold">{num+1}</span>
        </td>
        <td className="fw-bold">
          <Card.Link href={`/JackpotConfig/${id}` } className="d-flex align-items-center">
            {banner?<Image src={banner} className="image-small rounded-circle me-2" />:""}
            <div><span className="h6">{title}</span></div>
          </Card.Link>
        </td>
        {/* <td><Card.Link href={`/JackpotConfig/${id}` } ><div><span className="h6">{description}</span></div></Card.Link></td> */}
        <td><Card.Link href={`/JackpotConfig/${id}` } ><div><span className="h6">{stake ? stake : "--"}</span></div></Card.Link></td>
        <td><Card.Link href={`/JackpotConfig/${id}` } ><div><span className="h6">{stats ? stats[1] : "--"}</span></div></Card.Link></td>
        <td>
          <Row className="d-flex align-items-center">
            <Col md={3} lg={3}>
            <Card.Link href={`/JackpotConfig/${id}` }className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faEdit}  /> Edit </div></Card.Link>
            </Col>
            {/* <Col md={3} lg={3}>
              <Card.Link href={`/Choices/${id}`} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faList} /> {event_choices.length} Choices </div></Card.Link>
            </Col>
            <Col md={3} lg={3}>
              <Card.Link href={`/Prices/${id}`} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faDollarSign} /> {prizes.length} Prices </div></Card.Link>
            </Col> */}
            <Col md={3} lg={3}>
              <Card.Link onClick={()=>deleteJackpot(id)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faTrash} /> Delete </div></Card.Link>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <Card.Link href="/JackpotConfig" className="d-flex align-items-center"><div className="d-flex">
          <Button 
          onClick={()=>createNewJackpot()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Jackpot</span>
          </Button>
        </div></Card.Link>
      </div>
      {!loading ? <>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">Jackpot Title</th>
                  {/* <th className="border-0">Description</th> */}
                  <th className="border-0">stake</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {mainconfigs.map((jp,index) => <TableRow key={`jackpot-${jp.id}`} {...jp} num={index}/>)}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        
      </> : <Spinner />}

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
