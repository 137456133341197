import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { Col, Row, Button, Dropdown, Card,Table, Form,Image, InputGroup } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation } from 'react-router-dom';

import { Upload, Tooltip, Spin, Modal,Select } from 'antd';


import { UploadOutlined, LoadingOutlined, PlusOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore,faEdit,faList,faDollarSign, faEllipsisH, faExternalLinkAlt, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const { Option } = Select;
const { confirm } = Modal;

export default () => {
  const [matches, setMatches] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [topbets, setTopbets] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    authUser();
    setLoading(true);
    getTopbets();
    getLeagues("A");
    getMatches("A")
    // getCongigOptions();
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };
  const getTopbets = () => {
    LModel.find("octagon/top-bet/").then((response) => {
      if (response.data) {
        setTopbets(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }

  const getMatches = (query) => {
    LModel.find(`octagon/matches/${query}/`).then((response) => {
      if (response.data) {
        console.log(response.data);
        setMatches(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getLeagues = (query) => {
    LModel.find(`octagon/leagues/${query}/`).then((response) => {
      if (response.data) {
        setLeagues(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const  createNewJackpot =()=>{
    navigate('/Topbets');
  }
  const handleChange=(value)=> {
    console.log(`selected ${value}`);
    setSelectedLeague(value);

  }

  const handleLeagueSearch = value => {
      if(value)getLeagues(value);
  };
  const handleMatcheSearch = value => {
      if(value)getMatches(value);
  };

  const createTopbet = (e) => {
    e.preventDefault();
    setLoading(true);
  let url="octagon/top-bet/";
  let formData = new FormData();
  formData.append("league",Number(selectedLeague));

  LModel.createMultipart(url, formData).then(response => {
      console.log(response);
      if (response.data) {
        getTopbets();
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const removeTopbet = (e,id) => {
    e.preventDefault();
    let url="octagon/top-bet";
    confirm({
      title: 'Do you want to delete TopBet item?',
      icon: <ExclamationCircleOutlined />,
      content: `TopBet Item will be Deleted Permanently!`,
      onOk() {
        setLoading(true);
        return new Promise((resolve, reject) => {
          LModel.destroy(url, id).then(response => {
          console.log(response);
          // if (response.data) {
          // }
          getTopbets();
          setLoading(false);
          resolve()
        }).catch(err => {
          console.log(err);
          setLoading(false);
          reject(err);
        });
      }).catch((err) => console.log('Oops errors!',err));
    },
    onCancel() {},
  });

  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }
  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
  }

  const TableRow = (props) => {
    const { id, league_info,num} = props;
    let url=league_info.sport_type.logo;
    if(!validURL(url)){
      
      url=LModel.API_BASE_URL.slice(0,-1)+league_info.sport_type.logo;
    }
    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">{num+1}</Card.Link>
        </td>
        <td className="fw-bold">
          <span className="d-flex align-items-center">
           { league_info.sport_type && league_info.sport_type.logo?<Image src={url} className="image-small rounded-circle me-2" />:""}
            <div><span className="h6">{league_info.name}</span></div>
          </span>
        </td>
        <td>{league_info.league_group.name}</td>
        <td>{league_info.sport_type.name}</td>

        <td>
          <Row className="d-flex align-items-center">
            {/* <Col md={4} lg={4}>
            <Card.Link href={`/Topbets/${id}` }className="text-primary fw-bold"><div className="btn btn-primary"><FontAwesomeIcon icon={faEdit}  /> Edit </div></Card.Link>
            </Col> */}
            <Col md={4} lg={4}>
            <div className="btn btn-light" onClick={(e)=>removeTopbet(e,id)}><FontAwesomeIcon icon={faTrash}  /> Remove </div>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };


  const OptionsList = [];
  for (let i = 0; i < leagues.length; i++) {
    const tb = leagues[i];
    OptionsList.push(<Option key={i} value={tb.pk} label={tb.name + " - " + tb.league_group.name + " - " + tb.sport_type.name + ""}>{tb.name + " - " + tb.league_group.name + " - " + tb.sport_type.name + ""}</Option>)
  }
  const OptionsMatches = [];
  for (let i = 0; i < matches.length; i++) {
    const tb = matches[i];
    OptionsMatches.push(<Option key={i} value={tb.pk} label={tb.local_team + " - VS - " + tb.visitor_team + ""}>{tb.local_team + " - VS - " + tb.visitor_team + ""}</Option>)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-flex">
          <Button 
          onClick={()=>createNewJackpot()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>Create Top Bet</span>
          </Button>
        </div>
      </div>
      <>
        <Row> <Col xs={12} xl={12}>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <Form>
            <Form.Group id="leagues" defaultValue={0}>
              <Form.Label>League:</Form.Label>
              <Select
                showSearch={true}
                value={selectedLeague}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleLeagueSearch(value)}
                onChange={handleChange}
                notFoundContent={null}
                style={{ width: '100%' }}
              >
                {OptionsList}
              </Select>
            </Form.Group>
            <div className="mt-3">
              <Button variant="primary" type="submit" onClick={(e) => createTopbet(e)}>Save</Button>
            </div>
          </Form>
        </Card.Body>
      </Card></Col></Row>
        
        </> 
      {!loading ? <>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">League</th>
                  <th className="border-0">Group</th>
                  <th className="border-0">Sport Type</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {topbets.map((tb,index) => <TableRow key={`jackpot-${tb.id}`} {...tb} num={index}/>)}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        
      </> : <Spinner/>}
    </>
  );
};
