import React, { useEffect, useRef, useState, useCallback } from "react";
import { Col, Row, Button, Card, Form,Table,Nav } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {  Spin,message,Select,Modal } from 'antd';

import { LoadingOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus,faTrash } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";
const { confirm } = Modal;
export default (props) => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState([]);
  const [prices, setPrices] = useState([]);
  const [configOptions, setConfigOptions] = useState("");
  const [jackpotOptions, setJackpotOptions] = useState([]);
  // const [jackpot, setJackpot] = useState({});
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    if(props.id) {
      // getJackpotById();
      getJackpotPrices(props.id);
    }else getPrices();
    getCongigOptions();
    getJackpots(); 
  }, []);
//   useEffect(() => {
//     getAllPricesConfig(); 
// }, [jackpot]);
  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };
  const getAllPricesConfig = (id) => {
    // setLoading(true);
    // console.log(jackpot)
    LModel.find(`octagon/superjackpot/prizes/${id}`,null,null).then((response) => {
      if (response.data) {
        setMainConfigs(response.data);
        // props.id && setPrices([response.data]);
        // setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      // setLoading(false);
    });
  }
  const deleteJackpotPrice = (id) => {
    confirm({
      title: 'Do you want to delete Price?',
      icon: <ExclamationCircleOutlined />,
      content: `Price will be Deleted Permanently!`,
      onOk() {
        setLoading(true);
        return new Promise((resolve, reject) => {
          LModel.destroy(`octagon/superjackpot/prizes`,id).then(response => {
          console.log(response);
          message.success("Price Removed Successfuly!")
          getPrices();
          setLoading(false);
          resolve()
        }).catch(err => {
          console.log(err);
          if(err.response.status==405)
            message.error("Delete Not Allowed");
          setLoading(false);
          reject(err);
        });
      }).catch((err) => console.log('Oops errors!',err));
    },
    onCancel() {},
  });

  }

  const getPrices = () => {
    LModel.find("octagon/superjackpot/prizes/").then((response) => {
      if (response.data) {
        // if(!props.id) 
        setPrices(response.data);
        // props.id && setMainConfigs(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
        // if(!mainconfigs.rule){
          const newMainconfigs={...mainconfigs};
          const rule=response.data.superjackpot_prize_rules[0][0];
          newMainconfigs.rule=rule.toString();
          if(props.id) newMainconfigs.jackpot=props.id;
          setMainConfigs(newMainconfigs);
        // }
        
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getJackpots = () => {
    LModel.find("octagon/superjackpot/jackpot/").then((response) => {
      if (response.data) {
        const newMainconfigs={...mainconfigs};
        const jackpot=response.data[0];
        if(props.id) {
          newMainconfigs.jackpot=props.id;
        }else{
          newMainconfigs.jackpot=jackpot.id;
        }
        // setMainConfigs(newMainconfigs);
        setJackpotOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getJackpotPrices = (id) => {
    LModel.find(`octagon/superjackpot/jackpot/${id}`).then((response) => {
      if (response.data) {
        setPrices(response.data.prizes);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getJackpotById = async() => {
    await LModel.find(`octagon/superjackpot/jackpot/${props.id}`).then(async (response) => {
      if (response.data) {
        // await setJackpot(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const  createNewPrice =()=>{
    // navigate('/Prices');
    if(configOptions.superjackpot_prize_rules.length>0 && configOptions.superjackpot_prize_rules[0].length>0){
      const rule=configOptions.superjackpot_prize_rules[0][0];
      const newPrice={rule:rule.toString()};
      if(props.id) newPrice.jackpot=props.id;
    setMainConfigs(newPrice);
  }else setMainConfigs({});
  }
  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const saveConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    const id = mainconfigs.id;
    const url = `octagon/superjackpot/prizes/${id}/`;
    const data = { ...mainconfigs };
    if (!mainconfigs.jackpot) data.jackpot = props.id;
    LModel.update(url, data).then(response => {
      if (response.data) {
        setMainConfigs(response.data);
        getJackpotPrices(props.id);
        setLoading(false);
      }
      getJackpotPrices(props.id);
    }).catch(err => {
      // console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }

  const createConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    let url = "octagon/superjackpot/prizes/";
    const data = { ...mainconfigs };
    console.log(mainconfigs," | ",data)
    if (!data.jackpot && props.id) data.jackpot = props.id;
    LModel.create(url, data).then(response => {
      if (response.data) {
        setMainConfigs(response.data);
        getJackpotPrices(props.id);
        props.refreshJackpotConfig();
        setLoading(false);
      }
      getJackpotPrices();
    }).catch(err => {
      // console.log(err.response.data[Object.keys(err.response.data)[0]][0]);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);      
      setLoading(false);
    });
  }
  const onConfigChange = (key,value)=>{
    console.log(value);
    const newConfig={...mainconfigs};
    newConfig[key]=value;
    setMainConfigs(newConfig);
  } 

  const TableRow = (props) => {
    const { id, amount, rule, jackpot,num } = props;

    const selectedRule =configOptions.superjackpot_prize_rules? configOptions.superjackpot_prize_rules.filter(rl=>rule==rl[0]):[];
    const selectedJackpot=jackpotOptions.filter(jp=>jackpot==jp.id);

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">{num}</Card.Link>
        </td>
        <td className="fw-bold">
            <div><span className="h6">{amount}</span></div>
        </td>
        <td>{selectedRule.length>0?selectedRule[0][1]:""}</td>
        {/* <td>{selectedJackpot.length>0?selectedJackpot[0].title:""}</td> */}
        <td>
          <Row >
            <Col md={4} lg={4}>
            <Nav.Link onClick={()=>getAllPricesConfig(id)}className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faEdit}  /> Edit </div></Nav.Link>
            </Col>
            <Col md={4} lg={4}>
            <Nav.Link onClick={()=>deleteJackpotPrice(id)}className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faTrash}  /> Delete </div></Nav.Link>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-flex">
          <Button 
          onClick={()=>createNewPrice()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Price</span>
          </Button>
        </div>
      </div><>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Prices Configuration</h5>
              <Form>
                <Row>
                <Col md={4} className="mb-3">
                    <Form.Group id="amount">
                      <Form.Label>Amount:</Form.Label>
                      <Form.Control required type="number" defaultValue={mainconfigs.amount} onChange={(e) => onConfigChange('amount',e.target.value)} />
                    </Form.Group>
                  </Col>
                {/* </Row>

                <Row> */}
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule">
                      <Form.Label>Rule:</Form.Label>
                      <Form.Select id="rule" value={mainconfigs.rule} onChange={(e) => onConfigChange('rule',e.target.value)} >
                        {configOptions.superjackpot_prize_rules && configOptions.superjackpot_prize_rules.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={4} md={4} >
                    <Form.Group id="jackpot">
                      <Form.Label>Jackpot:</Form.Label>
                      <Form.Select id="jackpot" value={props.id} disabled={props.id?true:false} onChange={(e) => onConfigChange('jackpot',e.target.value)}>
                        {jackpotOptions && jackpotOptions.map((option, idx) => {
                          return (
                            <option key={idx} value={option.id}>{option.title}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-3">
                  {!mainconfigs.id?<Button variant="primary" type="submit" onClick={(e) => createConfig(e)}>Create</Button>:<Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row>
        
      </> 

      {!loading ? <>
              <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                  <Table responsive className="table-centered table-nowrap rounded mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Amount</th>
                        <th className="border-0">Rule</th>
                        {/* <th className="border-0">Jackpot</th> */}
                        <th className="border-0">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prices.map((jp,idx) => <TableRow key={`jackpot-${jp.id}`} {...jp}  num={idx+1}/>)}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              
            </> : <Spinner />}
    </>
  );
};
