import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { Col, Row, Button, Card, Form, InputGroup,Image,Table } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Upload, Spin, Modal,Tabs,Switch,message } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit,faList,faDollarSign,faCalendarAlt, faEye } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const FormControl= Form.Control;
const { TabPane } = Tabs;

export default () => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState([]);
  const [configOptions, setConfigOptions] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    getMainConfig();
    getCongigOptions();
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };

  const getMainConfig = () => {
    LModel.find("octagon/first-deposit-bonus/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        setMainConfigs(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const  createNewPromotion =()=>{
    setMainConfigs({});
  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const saveDeposit = (id) => {
    setLoading(true);
    // let url="octagon/first-deposit-bonus/config/";
    let url=`octagon/first-deposit-bonus/config/`;
    LModel.update(url,mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }
  const createDeposit = () => {
    setLoading(true);
    let url="octagon/first-deposit-bonus/config/";
    LModel.create(url,mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }

  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }
  const onConfigChange = (key,value)=>{
    console.log(value);
    const newConfig={...mainconfigs};
    newConfig[key]=value;
    setMainConfigs(newConfig);
  }

  return (
      <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-flex">
          <Button 
          onClick={()=>createNewPromotion()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Deposit</span>
          </Button>
        </div>
      </div>
      {!loading ? <>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Deposit Bonus</h5>
              <Form encType="multipart/form">
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="title">
                      <Form.Label>Deposit Bonus Enable:</Form.Label>
                      <Switch checked={mainconfigs.new_member_first_deposit_bonus_enable}  onChange={(value)=>onConfigChange('new_member_first_deposit_bonus_enable',value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="title">
                      <Form.Label>Bonus Criteria:</Form.Label>
                      <Form.Control required type="number" value={mainconfigs.new_member_first_deposit_bonus_criteria} onChange={(e)=>onConfigChange('new_member_first_deposit_bonus_criteria',e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Row>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="min_odd" defaultValue={0}>
                      <Form.Label>Bonus Min Amount:</Form.Label>
                      <Form.Control required type="number" value={mainconfigs.new_member_first_deposit_bonus_min_amount_criteria} onChange={(e) => onConfigChange('new_member_first_deposit_bonus_min_amount_criteria',e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="min_number_matches" defaultValue={0}>
                      <Form.Label>Bonus Max Amount:</Form.Label>
                      <Form.Control required type="number" value={mainconfigs.new_member_first_deposit_bonus_max_amount_criteria} onChange={(e) => onConfigChange('new_member_first_deposit_bonus_max_amount_criteria',e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="minStake" defaultValue={0}>
                      <Form.Label>Bonus Amount:</Form.Label>
                      <Form.Control required type="number" value={mainconfigs.new_member_first_deposit_bonus_amount} onChange={(e) => onConfigChange('new_member_first_deposit_bonus_amount',e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                </Row>
                <Row>
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule" defaultValue={0}>
                      <Form.Label>Award Rule:</Form.Label>
                      <Form.Select id="bonusType" value={mainconfigs.new_member_first_deposit_bonus_award_rule} onChange={(e) => onConfigChange('new_member_first_deposit_bonus_award_rule',e.target.value)} >
                        {configOptions.bonus_claim_award_rules && configOptions.bonus_claim_award_rules.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule" defaultValue={0}>
                      <Form.Label>Expires On:</Form.Label>
                      <Datetime
                        timeFormat={true}
                        closeOnSelect={false}
                        onChange={(date)=>onConfigChange('new_member_first_deposit_bonus_expires_on',date)}
                        renderInput={(props, openCalendar) => (
                          <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                            <Form.Control
                              required
                              type="text"
                              value={mainconfigs.new_member_first_deposit_bonus_expires_on ? moment(mainconfigs.new_member_first_deposit_bonus_expires_on).format("YYYY-MM-DDThh:mm") : ""}
                              placeholder="YYYY-MM-DDThh:mm"
                              onFocus={openCalendar}
                              onChange={() => { }} />
                          </InputGroup>
                        )} />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <div className="mt-3">
                  {mainconfigs.id?<Button variant="primary" type="submit" onClick={(e)=>saveDeposit(mainconfigs.id)}>Save</Button>:<Button variant="primary" type="submit" onClick={(e)=>createDeposit()}>Create</Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row>
        
      </> : <Spinner />}
    
    </>
  );
};
