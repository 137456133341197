
import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
// import { useHistory } from "react-router-dom";
import { useNavigate, Navigate,useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UserService from "../services/user.service";
import LModel from "../services/api";
import { RoutesPaths } from "../routes";
// import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import avatar from "../assets/img/team/avatar.png";
// import mulalogo from "../assets/img/technologies/mulalogo.png";


export default (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({});
  const [loggedin, setLoggedIn]=useState(true);

    useEffect(()=>{

      getUserInfo();

    },[]);
    
  const getUserInfo=() => {
    LModel.find('client')
      .then(response => {
        if (response.data) {
          let userRoles = [];
          setUserInfo(response.data.user)
          if (response.data.roles && response.data.roles.length) {
            userRoles = response.data.roles.map((role) => {
              return role.role;
            })
          }
          // let items = navigation.items.filter(nav => this.linkItemCanBeShown(nav, response.data.user, userRoles));
          // this.setState({ client: response.data, user: response.data.user, roles: userRoles, navigation: { items } })
        }
      }).catch((e) => {
        if (e && e.status === 400 && e.data.user) {
          let message = Object.keys(e.data.user).map(k => e.data.user[k]).join('\n');
          toast.error(`Error (${e.status}): ${message}`)
        } else if (e && e.status === 400) {
          let message = Object.keys(e.data).map(k => e.data[k]).join('\n');
          toast.error(`Error (${e.status}): ${message}`)
        } else if (e) {
          toast.error(`Error (${e.status}): ${e.statusText}`)
        } else {
          toast.error("Error: Unable to fetch Client!");
        }
        // let items = navigation.items.filter(nav => !nav.role);
        // this.setState({ navigation: { items } })
      });
  };

  const logout = async()=>{
    await UserService.logout();
    // history.push(RoutesPaths.Signin.path);
    // return navigate(RoutesPaths.Settings.path, {replace: true});
    // return <Navigate to={'/Autherization/sign-in'} />;
    // console.log(RoutesPaths.Signin.path);
    // console.log(location);
    // debugger;
    // if(loggedOut)
    
    // return <Navigate replace to={RoutesPaths.Signin.path} />;

    navigate(RoutesPaths.Signin.path, {replace: true});
    // props.redirect(RoutesPaths.Signup.path)
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
          </div>
          <Nav className="align-items-center">

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={avatar} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{userInfo.first_name + " "+userInfo.last_name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item> */}
                {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                </Dropdown.Item> */}

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={()=>logout()}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
