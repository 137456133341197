import React, { useEffect, useRef, useState, useCallback } from "react";
import { Col, Row, Button, Dropdown, Card, Form, InputGroup } from '@themesberg/react-bootstrap';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useNavigate,useLocation, useParams } from 'react-router-dom';

import { Upload, Tooltip,Spin,Modal } from 'antd';

import { LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";
import { RoutesPaths } from "../routes";


const type = 'DragableUploadList';
const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};

export default () => {
  
  const [mainconfigs, setMainConfigs] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [configType, setConfigType] = useState("All_Configurations");
  const [configOptions, setConfigOptions] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading,setLoading]=useState(false);

  const [previewVisible,setPreviewVisible]= useState(false);
  const [previewImage,setPreviewImage]= useState('');
  const [previewTitle,setPreviewTitle]= useState('');

  const [frontendSingleImage, setFrontendSingleImage] = useState([]);
  const [underageImg, setUnderageImg] = useState([]);
  const [loadingLogoImg, setLoadingLogoImg] = useState([]);
  const [logoImg, setLogoImg] = useState([]);


  const navigate= useNavigate();
  const location = useLocation();

  useEffect(() => {
    authUser();
    setLoading(true);
    getCongigurations();
    getCongigOptions();
    getFrontPageImages();
  }, []);

  const authUser=()=>{
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if(!isLoggedIn){
          return navigate(RoutesPaths.Signin.path);
      }
    });
  };
  const getCongigurations = () => {
    LModel.find("octagon/mainconfigs").then((response) => {
      if (response.data) {
        setMainConfigs(response.data);
        let fileName = (response.data.frontend_single_image.split("/"));
        let frontendImages = { uid: response.data.id, name: fileName[fileName.length - 1], status: 'done', url: response.data.frontend_single_image ,isCurrentImg:true};

        let underagefileName = (response.data.underage_img.split("/"));
        let underageImg = { uid: response.data.id, name: fileName[underagefileName.length - 1], status: 'done', url: response.data.underage_img ,isCurrentImg:true};

        let logofileName = (response.data.logo.split("/"));
        let logoImg = { uid: response.data.id, name: fileName[logofileName.length - 1], status: 'done', url: response.data.logo,isCurrentImg:true };

        let loagingLogofileName = (response.data.loading_logo.split("/"));
        let loadingLogoImg = { uid: response.data.id, name: fileName[loagingLogofileName.length - 1], status: 'done', url: response.data.loading_logo,isCurrentImg:true };
        
        setLoading(false);
        setFrontendSingleImage([frontendImages]);
        setUnderageImg([underageImg]);
        setLogoImg([logoImg]);
        setLoadingLogoImg([loadingLogoImg]);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }

  const getFrontPageImages = () => {
    LModel.find("octagon/mainconfigs/front-page-images/").then((response) => {
      if (response.data) {
        console.log(response.data);
        let images = response.data.map(item => {
          let fileName = (item.photo.split("/"))
          return { uid: item.id, name: fileName[fileName.length - 1],status: 'done', url: item.photo,isCurrentImg:true }
        });
        setFileList(images);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const onConfigChange = (key,value)=>{
    console.log(value);
    const newEditedData={...editedData};
    const newConfig={...mainconfigs};
    newConfig[key]=value;
    newEditedData[key]=value;
    setMainConfigs(newConfig);
    setEditedData(newEditedData);
  } 

  const saveFrontPageImageConfigurationList=(e)=>{
    e.preventDefault();
    if(fileList.length==0 || fileList[0].isCurrentImg) return console.log("Please add Image and order ");
    LModel.upload("octagon/mainconfigs/front-page-images/",fileList).then(response=>{
      if(response.data){
        console.log("Upload successful");
      }
    }).catch(err=>{
      console.log(err);
    });
    
  }

  const saveFrontEndTemplateImage=(e)=>{
    e.preventDefault();
    saveFrontendSingleImg();
    saveLogoImg();
    saveLoadingLogoImg();
    saveUnderageImg();
    
  }

  const fileUploader=(fileList)=>{
    if(fileList.length==0 || fileList[0].isCurrentImg) return console.log("Please add Image and order ");
    LModel.upload("octagon/mainconfigs/front-page-images/",fileList).then(response=>{
      if(response.data){
        console.log("Upload successful");
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  const Spinner=()=>{
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{textAlign: "center"}}><Spin indicator={antIcon} /></div>;
  }

  const saveConfig=(e)=>{
    e.preventDefault();
    LModel.update("octagon/mainconfigs/",editedData).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
      }
      setEditedData({})
    }).catch(err=>{
      console.log(err);
    });
  }
  
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [fileList],
  );

  const onFileChange = ({ fileList: newFileList }) => {
    console.log(newFileList);
    setFileList(newFileList);
  };

  const onFrontEndFileChange = ({ fileList: newFileList }) => {
    setFrontendSingleImage(newFileList);
    setMainConfigs({...mainconfigs,frontend_single_image:newFileList.url});
  };
  const saveFrontendSingleImg=()=>{
    fileUploader(frontendSingleImage);
  }


  const onLogoFileChange = ({ fileList: newFileList }) => {
    setLogoImg(newFileList);
    setMainConfigs({...mainconfigs,logo:newFileList.url});
  };

  const saveLogoImg=()=>{
    fileUploader(logoImg);
  }

  const onloadingLogoFileChange = ({ fileList: newFileList }) => {
    setLoadingLogoImg(newFileList);
    setMainConfigs({...mainconfigs,loading_logo:newFileList.url});
  };
  const saveLoadingLogoImg=()=>{
    fileUploader(loadingLogoImg);
  }

  const onUnderageFileChange = ({ fileList: newFileList }) => {
    setUnderageImg(newFileList);
    setMainConfigs({...mainconfigs,underage_img:newFileList.url});
  };  
  const saveUnderageImg=(e)=>{
    fileUploader(underageImg);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  
  const handleCancel = () =>setPreviewVisible(false);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Dropdown>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item>
              <FontAwesomeIcon icon={faFileAlt} className="me-2" /> Document
            </Dropdown.Item>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faCommentDots} className="me-2" /> Message
            </Dropdown.Item>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faBoxOpen} className="me-2" /> Product
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item>
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Subscription Plan
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        
          <div className="d-flex">
            <Dropdown onChange={(value) => console.log(value)}>
              <Dropdown.Toggle as={Button} variant="primary">
                <FontAwesomeIcon icon={faClipboard} className="me-2" /> Configurations
                <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown} /></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                <Dropdown.Item onClick={() => setConfigType("Bets_and_Slips_Configurations")}>
                  <FontAwesomeIcon icon={faBoxOpen} className="me-2" /> Bets and Slips Configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Jackpot_Configurations")}>
                  <FontAwesomeIcon icon={faStore} className="me-2" /> Jackpot Configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Withdrawal_Configurations")}>
                  <FontAwesomeIcon icon={faCartArrowDown} className="me-2" /> Withdrawal Configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Deposit_Configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Deposit Configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("General_Configuration")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> General Configuration
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Transfer_Configuration")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Transfer Configuration
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Referral_Configuration")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Referral Configuration
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Wallet_Configuration")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Wallet Configuration
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("SMS_Notification_Configuration")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> SMS Notification Configuration
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Payment_Method_Configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Payment Method Configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("digimoney_enable_Configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> DIGI Money Configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Company_Informations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Company Informations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Sales_Configuration")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Sales Configuration
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Sign_up_configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Sign up configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Slip_View_configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Slip View configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Branch_configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Branch configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("MoMo_configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> MoMo configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("Dashboard_configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> Dashboard configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("frontEnd_template_configurations")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> FrontEnd Template Configurations
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setConfigType("FRONT_PAGE_IMAGE_CONFIGURATIONS")}>
                  <FontAwesomeIcon icon={faChartPie} className="me-2" /> FRONT PAGE IMAGE CONFIGURATIONS
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item onClick={() => setConfigType("All_Configurations")}>
                  <FontAwesomeIcon icon={faRocket} className="text-success me-2" /> All Configurations
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
      </div>
      {!loading ? <>
      {/* <Row> */}
      {configType == "Bets_and_Slips_Configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">General information</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="maximum_pending_stake">
                    <Form.Label>Maximum amount of allowed pending stake:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.maximum_pending_stake || 0} value={mainconfigs.maximum_pending_stake} placeholder="No." onChange={(e) => onConfigChange("maximum_pending_stake", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="maximum_pending_slips">
                    <Form.Label>Maximum number of pending slips:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.maximum_pending_slips || 0} value={mainconfigs.maximum_pending_slips || 0} placeholder="No." onChange={(e) => onConfigChange("maximum_pending_slips", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="maximum_duplicate_slips">
                    <Form.Label>Maximum number of duplicate slips per user:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.maximum_duplicate_slips || 0} value={mainconfigs.maximum_duplicate_slips || 0} placeholder="No." onChange={(e) => onConfigChange("maximum_duplicate_slips", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={4} className="mb-3">
                  <Form.Group id="online_minimum_stake">
                    <Form.Label>Online Bet Minimum Stake:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.online_minimum_stake || 0} value={mainconfigs.online_minimum_stake || 0} placeholder="No." onChange={(e) => onConfigChange("online_minimum_stake", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="offline_minimum_stake">
                    <Form.Label>Offline Bet Minimum Stake:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.offline_minimum_stake || 0} value={mainconfigs.offline_minimum_stake || 0} placeholder="No." onChange={(e) => onConfigChange("offline_minimum_stake", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="maximum_stake" defaultValue={0}>
                    <Form.Label>Maximum Stake:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.maximum_stake || 0} value={mainconfigs.maximum_stake || 0} placeholder="No." onChange={(e) => onConfigChange("maximum_stake", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Offlineticket sold criteria</Form.Label>
                    <Form.Select id="offlineticket_sold_criteria" value={mainconfigs.offlineticket_sold_criteria} onChange={(e) => onConfigChange("offlineticket_sold_criteria", e.target.value)}    >
                      {configOptions.offline_tickets_sold_criteria && configOptions.offline_tickets_sold_criteria.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="system_max_duplicate_slips" defaultValue={0}>
                    <Form.Label>[System] Maximum number of allowed duplicate slips:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.system_max_duplicate_slips || 0} value={mainconfigs.system_max_duplicate_slips || 0} placeholder="No." onChange={(e) => onConfigChange("system_max_duplicate_slips", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="system_number_pick_duplicate_slips" defaultValue={0}>
                    <Form.Label>[System] Number of picks for a duplicated slip :</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.system_number_pick_duplicate_slips || 0} value={mainconfigs.system_number_pick_duplicate_slips || 0} placeholder="No." onChange={(e) => onConfigChange("system_number_pick_duplicate_slips", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>

              {/* <h5 className="my-4">Address</h5> */}
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Show TICKETID on dashboard:</Form.Label>
                    <Form.Select id="bets_cancel_window_after_match" value={mainconfigs.dashboard_show_ticketID} onChange={(e) => onConfigChange("dashboard_show_ticketID", e.target.value)} >
                      {configOptions.show_ticketID && configOptions.show_ticketID.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="bets_cancel_window_after_match" defaultValue={0}>
                    <Form.Label>Bets cancel window after match:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.bets_cancel_window_after_match || 0} value={mainconfigs.bets_cancel_window_after_match || 0} placeholder="No." onChange={(e) => onConfigChange("bets_cancel_window_after_match", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="sales_cancel_window" defaultValue={0}>
                    <Form.Label>Sales cancel window:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.sales_cancel_window || 0} value={mainconfigs.sales_cancel_window || 0} placeholder="No." onChange={(e) => onConfigChange("sales_cancel_window", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} >
                  <Form.Group id="cashout_rule" defaultValue={0}>
                    <Form.Label>Cashout rule:</Form.Label>
                    <Form.Select id="cashout_rule" value={mainconfigs.cashout_rule} onChange={(e) => onConfigChange("cashout_rule", e.target.value)} >
                      {configOptions.cashout_rule && configOptions.cashout_rule.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Check label="show Cashout rule" id="show_cashout_rule" htmlFor="checkbox1" checked={mainconfigs.show_cashout_rule || false} value={mainconfigs.show_cashout_rule || false} onClick={(e) => onConfigChange("show_cashout_rule", e.target.checked)} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="bets_cancel_window_after_match" defaultValue={0}>
                    <Form.Label>Offline coupon id length:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.bets_cancel_window_after_match || 0} value={mainconfigs.bets_cancel_window_after_match || 0} placeholder="No." onChange={(e) => onConfigChange("bets_cancel_window_after_match", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="sales_cancel_window" defaultValue={0}>
                    <Form.Label>Offline ticket id length:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.sales_cancel_window || 0} value={mainconfigs.sales_cancel_window || 0} placeholder="No." onChange={(e) => onConfigChange("sales_cancel_window", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="bets_cancel_window_after_match" defaultValue={0}>
                    <Form.Label>Online ticket id length:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.bets_cancel_window_after_match || 0} value={mainconfigs.bets_cancel_window_after_match || 0} placeholder="No." onChange={(e) => onConfigChange("bets_cancel_window_after_match", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="sales_cancel_window" defaultValue={0}>
                    <Form.Label>Bet Slip Valid Period (Days):</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.sales_cancel_window || 0} value={mainconfigs.sales_cancel_window || 0} placeholder="No." onChange={(e) => onConfigChange("sales_cancel_window", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group id="sales_cancel_window" defaultValue={0}>
                    <Form.Label>Basic cashout valid duration:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.sales_cancel_window || 0} value={mainconfigs.sales_cancel_window || 0} placeholder="No." onChange={(e) => onConfigChange("sales_cancel_window", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} >
                  <Form.Group id="slip_computer" defaultValue={0}>
                    <Form.Label>Slip Computer:</Form.Label>
                    <Form.Select id="slip_computer" value={mainconfigs.slip_computer} onChange={(e) => onConfigChange("slip_computer", e.target.value)} >
                      {configOptions.slip_computer && configOptions.slip_computer.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Jackpot_Configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Jackpot Configurations</h5>
            <Form>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Check label="Super jackpot offline support" id="super_jackpot_offline_support" htmlFor="checkbox1" checked={mainconfigs.super_jackpot_offline_support||false} onClick={(e) => onConfigChange("super_jackpot_offline_support", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Withdrawal_Configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Withdrawal Configurations</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="minimum_withdraw_amount">
                    <Form.Label>Minimum allowed Withdraw amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.minimum_withdraw_amount || 0} value={mainconfigs.minimum_withdraw_amount || 0} placeholder="No." onChange={(e) => onConfigChange("minimum_withdraw_amount", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="maximum_daily_withdraw">
                    <Form.Label>Maximum Allowed daily withdraw:</Form.Label>
                    <Form.Control required type="number" defaultValue={mainconfigs.maximum_daily_withdraw || 0} value={mainconfigs.maximum_daily_withdraw || 0} placeholder="No." onChange={(e) => onConfigChange("maximum_daily_withdraw", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Branch withdrawal enabled" id="branch_withdrawal_enabled" htmlFor="branch_withdrawal_enabled" checked={mainconfigs.branch_withdrawal_enabled || false} value={mainconfigs.branch_withdrawal_enabled || false} onChange={(e) => onConfigChange("branch_withdrawal_enabled", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Online withdrawal enabled" id="online_withdrawal_enabled" htmlFor="online_withdrawal_enabled" checked={mainconfigs.online_withdrawal_enabled || false} onChange={(e) => onConfigChange("online_withdrawal_enabled", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Withdrawal contact confirm" id="withdrawal_contact_confirm" htmlFor="withdrawal_contact_confirm" checked={mainconfigs.withdrawal_contact_confirm || false} onChange={(e) => onConfigChange("withdrawal_contact_confirm", e.target.checked)} />
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="All balance withdrawable" id="all_balance_withdrawable" htmlFor="all_balance_withdrawable" checked={mainconfigs.all_balance_withdrawable || false} onChange={(e) => onConfigChange("all_balance_withdrawable", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Useridentifier withdraw editable" id="useridentifier_withdraw_editable" htmlFor="useridentifier_withdraw_editable" checked={mainconfigs.useridentifier_withdraw_editable || false} onChange={(e) => onConfigChange("useridentifier_withdraw_editable", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Deposit_Configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Deposit Configurations</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="minimum_deposit_amount">
                    <Form.Label>Minimum allowed Deposit amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={0} placeholder="No." value={mainconfigs.minimum_deposit_amount || 0} onChange={(e) => onConfigChange("minimum_deposit_amount", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="maximum_deposit_amount">
                    <Form.Label>Maximum allowed Deposit amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={0} placeholder="No." value={mainconfigs.maximum_deposit_amount || 0} onChange={(e) => onConfigChange("maximum_deposit_amount", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="branch_max_single_deposit_amount">
                    <Form.Label>Branch max single deposit amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={0} placeholder="No." value={mainconfigs.branch_max_single_deposit_amount || 0} onChange={(e) => onConfigChange("branch_max_single_deposit_amount", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Online deposit enabled" id="online_deposit_enabled" htmlFor="online_deposit_enabled" checked={mainconfigs.online_deposit_enabled || false} onChange={(e) => onConfigChange("online_deposit_enabled", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Useridentifier deposit editable" id="useridentifier_deposit_editable" htmlFor="useridentifier_deposit_editable" checked={mainconfigs.useridentifier_deposit_editable || false} onChange={(e) => onConfigChange("useridentifier_deposit_editable", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "General_Configuration" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">General Configurations</h5>
            <Form>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Currency:</Form.Label>
                    <Form.Select id="currency" value={mainconfigs.currency} onChange={(e) => onConfigChange("currency", e.target.value)} >
                      {configOptions.currency && configOptions.currency.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Country Code:</Form.Label>
                    <Form.Select id="country_code" value={mainconfigs.country_code} onChange={(e) => onConfigChange("country_code", e.target.value)} >
                      {configOptions.country_codes && configOptions.country_codes.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                    <Form.Group className="mb-2">
                      <Form.Label>Country:</Form.Label>
                      <Form.Select id="country" value={mainconfigs.country} onChange={(e) => onConfigChange("country", e.target.value)} >
                        {configOptions.countries && configOptions.countries.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
              </Row>
                <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="min_allowed_transfer_amount">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control required type="text" placeholder="name" value={mainconfigs.name} onChange={(e) => onConfigChange("name", e.target.value)} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="min_allowed_transfer_amount">
                    <Form.Label>Domain name:</Form.Label>
                    <Form.Control required type="text" placeholder="domain name" value={mainconfigs.domain_name} onChange={(e) => onConfigChange("domain_name", e.target.value)} />
                  </Form.Group>
                </Col>
                </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Offline bet support" id="offline_bet_support" htmlFor="offline_bet_support" checked={mainconfigs.offline_bet_support || false} onChange={(e) => onConfigChange("offline_bet_support", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Offline payout enabled" id="offline_payout_enabled" htmlFor="offline_payout_enabled" checked={mainconfigs.offline_payout_enabled || false} onChange={(e) => onConfigChange("offline_payout_enabled", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Transfer_Configuration" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Transfer Configurations</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Transfer enabled" id="transfer_enabled" htmlFor="transfer_enabled" checked={mainconfigs.transfer_enabled || false} onChange={(e) => onConfigChange("transfer_enabled", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Transfer contact confirm" id="transfer_contact_confirm" htmlFor="transfer_contact_confirm" checked={mainconfigs.transfer_contact_confirm || false} onChange={(e) => onConfigChange("transfer_contact_confirm", e.target.checked)} />
                </Col></Row><Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="min_allowed_transfer_amount">
                    <Form.Label>Min allowed transfer amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={0} placeholder="No." value={mainconfigs.min_allowed_transfer_amount || 0} onChange={(e) => onConfigChange("min_allowed_transfer_amount", e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Referral_Configuration" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Referral Configurations</h5>
            <Form>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Referral type:</Form.Label>
                    <Form.Select id="referral_type" value={mainconfigs.referral_type} onChange={(e) => onConfigChange("referral_type", e.target.value)} >
                      {configOptions.referral_types && configOptions.referral_types.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Referral support" id="referral_support" htmlFor="referral_support" checked={mainconfigs.referral_support || false} onChange={(e) => onConfigChange("referral_support", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Referral badged commission support" id="referral_badged_commission_support" htmlFor="referral_badged_commission_support" checked={mainconfigs.referral_badged_commission_support || false} onChange={(e) => onConfigChange("referral_badged_commission_support", e.target.checked)} />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="referral_criteria_min_bet_amount">
                    <Form.Label>Referral criteria min bet amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={0} placeholder="No." onChange={(e) => onConfigChange("referral_criteria_min_bet_amount", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="referral_criteria_min_deposit_amount">
                    <Form.Label>Referral criteria min deposit amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={0} placeholder="No." onChange={(e) => onConfigChange("referral_criteria_min_deposit_amount", Number(e.target.value))} />
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group id="referral_award_amount">
                    <Form.Label>Referral award amount:</Form.Label>
                    <Form.Control required type="number" defaultValue={0} placeholder="No." onChange={(e) => onConfigChange("referral_award_amount", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Wallet_Configuration" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Wallet Configurations</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="wallet_balance_deposit_limit">
                    <Form.Label>Wallet balance deposit limit:</Form.Label>
                    <Form.Control required type="number" onChange={(e) => onConfigChange("wallet_balance_deposit_limit", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "SMS_Notification_Configuration" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">SMS Notification Configuration</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Enable registeration confirmation sms" id="enable_registeration_confirmation_sms" htmlFor="enable_registeration_confirmation_sms" checked={mainconfigs.enable_registeration_confirmation_sms || false} onChange={(e) => onConfigChange("enable_registeration_confirmation_sms", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Enable phone confirmation sms" id="enable_phone_confirmation_sms" htmlFor="enable_phone_confirmation_sms" checked={mainconfigs.enable_phone_confirmation_sms || false} onChange={(e) => onConfigChange("enable_phone_confirmation_sms", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Enable password reset sms" id="enable_password_reset_sms" htmlFor="enable_password_reset_sms" checked={mainconfigs.enable_password_reset_sms || false} onChange={(e) => onConfigChange("enable_password_reset_sms", e.target.checked)} />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Enable bet placed sms" id="enable_bet_placed_sms" htmlFor="enable_bet_placed_sms" checked={mainconfigs.enable_bet_placed_sms || false} onChange={(e) => onConfigChange("enable_bet_placed_sms", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Enable deposit succeed sms" id="enable_deposit_succeed_sms" htmlFor="enable_deposit_succeed_sms" checked={mainconfigs.enable_deposit_succeed_sms || false} onChange={(e) => onConfigChange("enable_deposit_succeed_sms", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Enable bet cancelled sms" id="enable_bet_cancelled_sms" htmlFor="enable_bet_cancelled_sms" checked={mainconfigs.enable_bet_cancelled_sms || false} onChange={(e) => onConfigChange("enable_bet_cancelled_sms", e.target.checked)} />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Enable bet win notification sms" id="enable_bet_win_notification_sms" htmlFor="enable_bet_win_notification_sms" checked={mainconfigs.enable_bet_win_notification_sms || false} onChange={(e) => onConfigChange("enable_bet_win_notification_sms", e.target.checked)} />
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="max_win_for_win_notification">
                    <Form.Label>Max win for win notification:</Form.Label>
                    <Form.Control required type="number" onChange={(e) => onConfigChange("max_win_for_win_notification", Number(e.target.value))} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Payment_Method_Configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Payment Method Configurations</h5>
            <Form>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Amole enable option:</Form.Label>
                    <Form.Select id="amole_enable_option" value={mainconfigs.amole_enable_option} onChange={(e) => onConfigChange("amole_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Hellocash lion enable option:</Form.Label>
                    <Form.Select id="hellocash_lion_enable_option" value={mainconfigs.hellocash_lion_enable_option} onChange={(e) => onConfigChange("hellocash_lion_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Hellocash wegagen enable option:</Form.Label>
                    <Form.Select id="hellocash_wegagen_enable_option" value={mainconfigs.hellocash_wegagen_enable_option} onChange={(e) => onConfigChange("hellocash_wegagen_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney enable option:</Form.Label>
                    <Form.Select id="digimoney_enable_option" value={mainconfigs.digimoney_enable_option} onChange={(e) => onConfigChange("digimoney_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Ebirr enable optio:</Form.Label>
                    <Form.Select id="ebirr_enable_option" value={mainconfigs.ebirr_enable_option} onChange={(e) => onConfigChange("ebirr_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Mtn momo enable option:</Form.Label>
                    <Form.Select id="mtn_momo_enable_option" value={mainconfigs.mtn_momo_enable_option} onChange={(e) => onConfigChange("mtn_momo_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Branch payment enable option:</Form.Label>
                    <Form.Select id="branch_payment_enable_option" value={mainconfigs.branch_payment_enable_option} onChange={(e) => onConfigChange("branch_payment_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>CBEbirr enable option:</Form.Label>
                    <Form.Select id="cbebirr_enable_option" value={mainconfigs.cbebirr_enable_option} onChange={(e) => onConfigChange("cbebirr_enable_option", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "digimoney_enable_Configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">DIGI Money Configurations</h5>
            <Form>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney fnb:</Form.Label>
                    <Form.Select id="digimoney_fnb" value={mainconfigs.digimoney_fnb} onChange={(e) => onConfigChange("digimoney_fnb", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney fnb wallet:</Form.Label>
                    <Form.Select id="digimoney_fnb_wallet" value={mainconfigs.digimoney_fnb} onChange={(e) => onConfigChange("digimoney_fnb_wallet", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney fnb eft:</Form.Label>
                    <Form.Select id="digimoney_fnb_eft" value={mainconfigs.digimoney_fnb_eft} onChange={(e) => onConfigChange("digimoney_fnb_eft", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney stndbank:</Form.Label>
                    <Form.Select id="digimoney_stndbank" value={mainconfigs.digimoney_stndbank} onChange={(e) => onConfigChange("digimoney_stndbank", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney emali:</Form.Label>
                    <Form.Select id="digimoney_emali" value={mainconfigs.digimoney_emali} onChange={(e) => onConfigChange("digimoney_emali", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney ecocash:</Form.Label>
                    <Form.Select id="digimoney_ecocash" value={mainconfigs.digimoney_ecocash} onChange={(e) => onConfigChange("digimoney_ecocash", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney mpesa:</Form.Label>
                    <Form.Select id="digimoney_mpesa" value={mainconfigs.digimoney_mpesa} onChange={(e) => onConfigChange("digimoney_mpesa", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Digimoney momo:</Form.Label>
                    <Form.Select id="digimoney_momo" value={mainconfigs.digimoney_momo} onChange={(e) => onConfigChange("digimoney_momo", e.target.value)} >
                      {configOptions.payment_method_enable_options && configOptions.payment_method_enable_options.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}

      {configType == "Company_Informations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Company Informations</h5>
            <Form>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Facebook</Form.Label>
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faFacebookF} className=" text-facebook me-2" /></InputGroup.Text>
                      <Form.Control type="text" placeholder="Facebook link" value={mainconfigs.facebook} onChange={(e) => onConfigChange("facebook", e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Twitter</Form.Label>
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faTwitter} className=" text-twitter me-2" /></InputGroup.Text>
                      <Form.Control type="text" placeholder="Twitter link" value={mainconfigs.twitter} onChange={(e) => onConfigChange("twitter", e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Instagram</Form.Label>
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faInstagram} className=" text-instagram me-2" /></InputGroup.Text>
                      <Form.Control type="text" placeholder="Instagram link" value={mainconfigs.instagram} onChange={(e) => onConfigChange("instagram", e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Telegram</Form.Label>
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faTelegram} className=" text-twitter me-2" /></InputGroup.Text>
                      <Form.Control type="text" placeholder="Telegram link" value={mainconfigs.telegram} onChange={(e) => onConfigChange("telegram", e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Telephone</Form.Label>
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text>
                      <Form.Control required type="text" placeholder="Telephone " value={mainconfigs.telephone} onChange={(e) => onConfigChange("telephone", e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4} md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                      <Form.Control required type="email" placeholder="name@example.com" value={mainconfigs.email} onChange={(e) => onConfigChange("email", e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Underage rule:</Form.Label>
                    <Form.Control as="textarea" rows="5" value={mainconfigs.underage_rule} onChange={(e) => onConfigChange("underage_rule", e.target.value)} />
                  </Form.Group>
                </Col>
                <Col lg={12} md={12} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>About company:</Form.Label>
                    <Form.Control as="textarea" rows="5" value={mainconfigs.about_company} onChange={(e) => onConfigChange("about_company", e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Sales_Configuration" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Sales Configuration</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Slip duplicate enabled" id="slip_duplicate_enabled" htmlFor="slip_duplicate_enabled" checked={mainconfigs.slip_duplicate_enabled || false} value={mainconfigs.slip_duplicate_enabled} onChange={(e) => onConfigChange("slip_duplicate_enabled", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Sign_up_configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Sign up configurations</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Registeration enabled" id="registeration_enabled" htmlFor="registeration_enabled" checked={mainconfigs.registeration_enabled} onChange={(e) => onConfigChange("registeration_enabled", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Disable main registeration" id="disable_main_registeration" htmlFor="disable_main_registeration" checked={mainconfigs.disable_main_registeration} onChange={(e) => onConfigChange("disable_main_registeration", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Slip_View_configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Slip View configurations</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Show staketax information" id="show_staketax_information" htmlFor="show_staketax_information" checked={mainconfigs.show_staketax_information} onChange={(e) => onConfigChange("show_staketax_information", e.target.checked)} />
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Check label="Show wintax information" id="show_wintax_information" htmlFor="show_wintax_information" checked={mainconfigs.show_wintax_information} onChange={(e) => onConfigChange("show_wintax_information", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}
      {configType == "Branch_configurations" || configType == "All_Configurations" ? <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Branch configurations</h5>
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Check label="Show branch withdrawal request code" id="show_branch_withdrawal_request_code" htmlFor="show_branch_withdrawal_request_code" checked={mainconfigs.show_branch_withdrawal_request_code} onChange={(e) => onConfigChange("show_branch_withdrawal_request_code", e.target.checked)} />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Check label="Sms branch withdrawal request code" id="sms_branch_withdrawal_request_code" htmlFor="sms_branch_withdrawal_request_code" checked={mainconfigs.sms_branch_withdrawal_request_code} onChange={(e) => onConfigChange("sms_branch_withdrawal_request_code", e.target.checked)} />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Check label="Sms branch withdrawal" id="sms_branch_withdrawal" htmlFor="sms_branch_withdrawal" checked={mainconfigs.sms_branch_withdrawal} onChange={(e) => onConfigChange("sms_branch_withdrawal", e.target.checked)} />
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Check label="Sms branch deposit" id="sms_branch_deposit" htmlFor="sms_branch_deposit" checked={mainconfigs.sms_branch_deposit} onChange={(e) => onConfigChange("sms_branch_deposit", e.target.checked)} />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="maximum_pending_stake">
                      <Form.Label>Branch withdrawal trans fee:</Form.Label>
                      <Form.Control type="number" defaultValue={0.0} value={mainconfigs.maximum_pending_stake} onChange={(e) => onConfigChange("maximum_pending_stake", Number(e.target.value))} />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="maximum_pending_stake">
                      <Form.Label>Branch withdrawal trans suffix:</Form.Label>
                      <Form.Select id="branch_withdrawal_trans_suffix" value={mainconfigs.branch_withdrawal_trans_suffix} onChange={(e) => onConfigChange("branch_withdrawal_trans_suffix", e.target.value)} >
                        {configOptions.amount_suffix && configOptions.amount_suffix.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-3">
                  {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row> : ""}
      {configType == "MoMo_configurations" || configType == "All_Configurations" ? <Row>
        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">MoMo configurations</h5>
              <Form>

                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="momo_withdrawal_trans_fee">
                      <Form.Label>Momo withdrawal trans fee:</Form.Label>
                      <Form.Control type="number" defaultValue={0.0} value={mainconfigs.momo_withdrawal_trans_fee} onChange={(e) => onConfigChange("momo_withdrawal_trans_fee", e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="maximum_pending_stake">
                      <Form.Label>Momo withdrawal trans suffix:</Form.Label>
                      <Form.Select id="momo_withdrawal_trans_suffix" value={mainconfigs.maximum_pending_stake} onChange={(e) => onConfigChange("momo_withdrawal_trans_suffix", e.target.value)} >
                        {configOptions.amount_suffix && configOptions.amount_suffix.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-3">
                  {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row> : ""}
      {configType == "Dashboard_configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">Dashboard configurations</h5>
            <Form>
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Check label="Main dash enabled" id="main_dash_enabled" htmlFor="main_dash_enabled" checked={mainconfigs.main_dash_enabled} onChange={(e) => onConfigChange("main_dash_enabled", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {configType != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col></Row> : ""}

      {configType == "frontEnd_template_configurations" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>

        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">FrontEnd Template Configurations</h5>
              <Form>

                <Row>

                  <Col md={4} className="mb-3">
                    <Form.Label>Frontend template:</Form.Label>
                    <Form.Select id="frontend_template" value={mainconfigs.frontend_template} onChange={(e) => onConfigChange("frontend_template", e.target.value)} >
                      {configOptions.front_end_template && configOptions.front_end_template.map((option, idx) => {
                        return (
                          <option key={idx} value={option[0]}>{option[1]}</option>
                        );
                      })}
                    </Form.Select>
                  </Col>

                  <Col md={4} className="mb-3">

                    <Form.Label>Frontend template max height:</Form.Label>
                    <Form.Control type="number" defaultValue={0.0} value={mainconfigs.frontend_template_max_height} onChange={(e) => onConfigChange("frontend_template_max_height", e.target.value)} />
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="maximum_pending_stake">
                      <Form.Label>Frontend single image:</Form.Label>

                      <DndProvider backend={HTML5Backend}><Upload
                        listType="picture-card"
                        onPreview={handlePreview}
                        onChange={onFrontEndFileChange}
                        fileList={frontendSingleImage}
                        maxCount={1}
                      >
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      </Upload></DndProvider>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group id="maximum_pending_stake">
                      <Form.Label>Logo:</Form.Label>

                      <DndProvider backend={HTML5Backend}><Upload
                        listType="picture-card"
                        onPreview={handlePreview}
                        name="logoFrontendImage"
                        onChange={onLogoFileChange}
                        fileList={logoImg}
                        maxCount={1}
                      >
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      </Upload></DndProvider>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="maximum_pending_stake">
                      <Form.Label>Loading logo:</Form.Label>

                      <DndProvider backend={HTML5Backend}><Upload
                        listType="picture-card"
                        onPreview={handlePreview}
                        onChange={onloadingLogoFileChange}
                        fileList={loadingLogoImg}
                        maxCount={1}
                      >
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      </Upload></DndProvider>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="maximum_pending_stake">
                      <Form.Label>Underage img:</Form.Label>

                      <DndProvider backend={HTML5Backend}><Upload
                        listType="picture-card"
                        onPreview={handlePreview}
                        onChange={onUnderageFileChange}
                        fileList={underageImg}
                        maxCount={1}
                      >
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      </Upload></DndProvider>
                    </Form.Group>
                  </Col>
                </Row>

              </Form>
            <Row>
              <Col md={12}>
                <Button variant="primary" type="submit" onClick={saveFrontEndTemplateImage} >Save</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      </Row> : ""}

      {configType == "FRONT_PAGE_IMAGE_CONFIGURATIONS" || configType == "All_Configurations" ? <Row> <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">FRONT PAGE IMAGE CONFIGURATIONS</h5>
            <DndProvider backend={HTML5Backend}><Upload
              listType="picture-card"
              onPreview={handlePreview}
              onChange={onFileChange}
              fileList={fileList}
              itemRender={(originNode, file, currFileList) => (
                <DragableUploadListItem
                  originNode={originNode}
                  file={file}
                  fileList={currFileList}
                  moveRow={moveRow}
                />
              )}
            >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
            </Upload></DndProvider>
            <Row>
              <Col md={12}>
                <Button variant="primary" type="submit" onClick={saveFrontPageImageConfigurationList} >Save</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      </Row> : ""}
      {configType == "All_Configurations" ? <Row>
        <Col md={12}><Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <div className="mt-3">
              <Button variant="success" type="submit" onClick={(e) => saveConfig(e)}>Save All</Button>
            </div>
          </Card.Body>
        </Card></Col>
      </Row> : ""}
      </>:<Spinner/>}

      <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
    </>
  );
};
