// import * as session from 'browser-session-store'; //session store -> per tab
import * as session from "browser-store"; //local storage // per browser
import Cookies from "universal-cookie";

class ClientSession {
  static authkey = "MulaportAuth";
  static lngkey = "MulaportLang";
  static favkey = "MulaportFav";

  static loggedin = null;
  static cookies = new Cookies();

  static storeAuth = (value, func) => {
    session.put(ClientSession.authkey, value, err => func(err));
  };

  static storeLang = (value, func) => {
    session.put(ClientSession.lngkey, value, err => func(err));
  };

  static storeFav = (value, func) => {
    session.put(ClientSession.favkey, value, err => func(err));
  };

  static getAuth = reciverfunc => {
    session.get(ClientSession.authkey, (err, value) => reciverfunc(err, value));
  };

  static getLang = reciverfunc => {
    session.get(ClientSession.lngkey, (err, value) => reciverfunc(err, value));
  };

  static getFav = reciverfunc => {
    session.get(ClientSession.favkey, (err, value) => reciverfunc(err, value));
  };

  static updateAuthData = (newValue, func) => {
    session.get(ClientSession.authkey, (err, value) => {
      value.data = newValue;
      session.put(ClientSession.authkey, value, err => func(err));
    });
  };

  static removeAuth = func => {
    session.remove(ClientSession.authkey, err => {
      func(err);
    });
  };

  static isLoggedIn = func => {
    ClientSession.getAuth((err, value) => {
      if (err) {
        console.error(err);
        func(false);
      } else {
        if (value == null) {
          ClientSession.removeAuth(err => {
            if (err) {
              console.log(err);
            }
          });
          func(false);
        } else if (
          new Date(value.createdDate).getTime() + value.ttl >=
          new Date().getTime()
        ) {
          func(true);
        } else {
          if (value.languages != null && value.languages.length > 0) {
            func(true, true);
          } else {
            func(true, false);
          }
        }
      }
    });
  };

  static getToken = () => {
    if (ClientSession.isLoggedIn()) {
      ClientSession.getAuth((err, value) => {
        if (err) {
          console.error(err);
          return false;
        } else {
          return value.id;
        }
      });
    }
  };

  static getAccessToken = callback => {
    ClientSession.isLoggedIn(function(isLoggedIn) {
      if (isLoggedIn) {
        ClientSession.getAuth((err, value) => {
          if (err) {
            console.error(err);
            callback(false, err);
          } else {
            callback(true, value);
          }
        });
      } else {
        callback(false, null);
      }
    });
  };
  static getConvexitAccessToken = callback => {
    ClientSession.isLoggedIn(function(isLoggedIn) {
      if (isLoggedIn) {
        ClientSession.getConvexit((err, value) => {
          if (err) {
            console.error(err);
            callback(false, err);
          } else {
            callback(true, value);
          }
        });
      } else {
        callback(false, null);
      }
    });
  };
  static getJackpotAccessToken = callback => {
    ClientSession.isLoggedIn(function(isLoggedIn) {
      if (isLoggedIn) {
        ClientSession.getJackpot((err, value) => {
          if (err) {
            console.error(err);
            callback(false, err);
          } else {
            callback(true, value);
          }
        });
      } else {
        callback(false, null);
      }
    });
  };
}

export default ClientSession;
