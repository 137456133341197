import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { Col, Row, Button, Card, Form, InputGroup,Image,Table } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Upload, Spin, Modal,Tabs,message } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit,faList,faDollarSign, faEye } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const FormControl= Form.Control;
const { TabPane } = Tabs;

export default () => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState([]);
  const [title, setTitle] = useState("");

  const [freeBet, setFreeBet] = useState({});
  const [betBonusEnabled,setBetBonusEnabled] = useState(false);
  const [registerationBonusEnales,setRegisterationBonusEnales] = useState(false);

  const [betRules, setBetRules] = useState([]);
  const [betRuleDetail, setBetRuleDetail] = useState({});
  const [minOdd, setMinOdd] = useState("");
  const [minNumberMatches, setMinNumberMatches] = useState("");
  const [minStake, setMinStake] = useState("");
  const [awardRule, setAwardRule] = useState("");
  const [description, setDescription] = useState("");

  const [configOptions, setConfigOptions] = useState("");
  const [bonusType, setBonusType] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    // getMainConfig();
    getFreeBet();
    getBetRule();
    getCongigOptions();
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };

  const getMainConfig = () => {
    LModel.find("octagon/freebet/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        setMainConfigs(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const  reset =()=>{
    setMainConfigs([]);
    // setBetRuleDetail([]);
    // setBetRules([]);
    setFreeBet({});

  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const saveConfig = (e) => {
    e.preventDefault();
    setLoading(true);

    let url="octagon/freebet/config/";
    // if(mainconfigs.id)url=`octagon/freebet/config/`;

    LModel.update(url, freeBet).then(response => {
      console.log(response);
      if (response.data) {
        // setMainConfigs(response.data);
        getFreeBet();
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }

  const getFreeBet = () => {
    // e.preventDefault();
    setLoading(true);
    LModel.find("octagon/freebet/config/").then((response) => {
      if (response.data) {
        console.log(response.data);
        setFreeBet(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });

  }

  const getBetRule=()=>{
    LModel.find("octagon/freebet/bet-rule/").then((response) => {
      if (response.data) {
        console.log(response.data);
        setBetRules(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteFreeBet = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const saveLoyalityPointConfig = (e) => {
    e.preventDefault();
    setLoading(true);
     LModel.update("octagon/loyality-point/config/",mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }
  const getLoyalityPointConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    LModel.find("octagon/loyality-point/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        // setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteLoyalityPointConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const saveOnlineBetPolicyConfig = (e) => {
    e.preventDefault();
    setLoading(true);
     LModel.update("octagon/online-policy/config/",mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }
  const getOnlineBetPolicyConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    LModel.find("octagon/online-policy/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        // setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteOnlineBetPolicyConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const saveFirstDepositBonus = (e) => {
    e.preventDefault();
    setLoading(true);
     LModel.update("octagon/first-deposit-bonus/config/",mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }
  const saveBetRule = (id) => {
    setLoading(true);
     LModel.update(`octagon/freebet/bet-rule/${id}/`,betRuleDetail).then(response=>{
      if(response.data){
        // setMainConfigs(response.data);
        getBetRule();
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }
  const registerRule = () => {
    setLoading(true);
     LModel.create(`octagon/freebet/register-rule/`,betRuleDetail).then(response=>{
      if(response.data){
        // setMainConfigs(response.data);
        getBetRule();
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }
  const getFreebetConfiguration = (e) => {
    e.preventDefault();
    setLoading(true);
    LModel.find("octagon/freebet/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        // setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteFreebetConfiguration = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }
  const getFreeBetDetail = (id) => {
    // setLoading(true);
    // console.log(jackpot)
    LModel.find(`octagon/freebet/bet-rule/${id}`,null,null).then((response) => {
      if (response.data) {
        setBetRuleDetail(response.data)
        setMinOdd(response.data.min_odd);
        setMinNumberMatches(response.data.min_number_matches);
        setMinStake(response.data.min_stake);
        setAwardRule(response.data.award_rule);
        // params.id && setPrices([response.data]);
        // setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      // setLoading(false);
    });
  }

  const onConfigChange = (key,value)=>{
    const newConfig={...freeBet};
    newConfig[key]=value;
    setFreeBet(newConfig);
  } 

  const onBetRuleChange = (key,value)=>{
    const newConfig={...betRuleDetail};
    newConfig[key]=value;
    setBetRuleDetail(newConfig);
  } 
  const TableRow = (props) => {
    const { id,min_odd, min_number_matches, min_stake,award_rule,num } = props;
    // const bonus= configOptions.superjackpot_status && configOptions.superjackpot_status.find((option, idx) =>option[0] == bonus_type);
    return (
      <tr>
        <td>
          <span className="text-primary fw-bold">{num+1}</span>
        </td>
        <td className="fw-bold">
          <Card.Link onClick={()=>getFreeBetDetail(id)} className="d-flex align-items-center">
            {/* {banner?<Image src={banner} className="image-small rounded-circle me-2" />:""} */}
            <div><span className="h6">{min_odd}</span></div>
          </Card.Link>
        </td>
        <td><Card.Link onClick={()=>getFreeBetDetail(id)} ><div><span className="h6">{min_number_matches ? min_number_matches : "--"}</span></div></Card.Link></td>
        <td><Card.Link onClick={()=>getFreeBetDetail(id)} ><div><span className="h6">{min_stake ? min_stake : "--"}</span></div></Card.Link></td>
        <td><Card.Link onClick={()=>getFreeBetDetail(id)} ><div><span className="h6">{award_rule ? award_rule : "--"}</span></div></Card.Link></td>
        <td>
          <Row className="d-flex align-items-center">
            <Col md={4} lg={4}>
            <Card.Link onClick={()=>getFreeBetDetail(id)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faEdit}  /> Edit </div></Card.Link>
            </Col>
            {/* <Col md={4} lg={4}>
              <Card.Link href={`/Choices/${id}`} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faList} /> {event_choices.length} Choices </div></Card.Link>
            </Col>
            <Col md={4} lg={4}>
              <Card.Link href={`/Prices/${id}`} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faDollarSign} /> {prizes.length} Prices </div></Card.Link>
            </Col> */}
          </Row>
        </td>
      </tr>
    );
  };

  return (
      <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-flex">
          <Button 
          onClick={()=>reset()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Free Bet</span>
          </Button>
        </div>
      </div>
      {!loading ? <>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Free Bet</h5>
              <Form>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Check label="Bet Bonus Enabled" id="bet_bonus_enabled" htmlFor="checkbox1" defaultChecked={freeBet.bet_bonus_enabled} onClick={(e) => onConfigChange("bet_bonus_enabled", e.target.checked)} />
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Check label="Bet Bonus Enabled" id="registeration_bonus_enabled" htmlFor="checkbox1" defaultChecked={freeBet.registeration_bonus_enabled}  onClick={(e) => onConfigChange("registeration_bonus_enabled", e.target.checked)} />
                </Col>
              </Row>
              <div className="mt-3">
                {false != "All_Configurations" ? <Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button> : ""}
              </div>
            </Form>
            <hr></hr>
            
            </Card.Body>
          </Card>
        </Col></Row>
        <Row>
          <Col xs={12} xl={12}>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body >
          <h5 className="mb-4">Free Rule</h5>
          <Form encType="multipart/form">
                <Row>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="min_odd" defaultValue={0}>
                      <Form.Label>Min Odd:</Form.Label>
                      <Form.Control required type="number" value={betRuleDetail.min_odd} placeholder="Min Odd" onChange={(e) => onBetRuleChange("min_odd", parseFloat(e.target.value))} />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="min_number_matches" defaultValue={0}>
                      <Form.Label>Min Number Matches:</Form.Label>
                      <Form.Control required type="number" value={betRuleDetail.min_number_matches} placeholder="Min Number Matches" onChange={(e) => onBetRuleChange("min_number_matches", parseInt(e.target.value))} />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="minStake" defaultValue={0}>
                      <Form.Label>Min Stake:</Form.Label>
                      <Form.Control required type="number" value={betRuleDetail.min_stake} placeholder="Min Stake" onChange={(e) => onBetRuleChange("min_stake", parseInt(e.target.value))} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={4} >
                  <Form.Group id="award_rule" defaultValue={0}>
                      <Form.Label>Award Rule:</Form.Label>
                      <Form.Control required type="number" value={betRuleDetail.award_rule} placeholder="award_rule" onChange={(e) => onBetRuleChange("award_rule", parseInt(e.target.value))} />
                    </Form.Group>
                  </Col>
                </Row>
                
                <div className="mt-3">
                  {betRuleDetail.id?<Button variant="primary" type="submit" onClick={(e)=>saveBetRule(betRuleDetail.id)}>Save</Button>:<Button variant="primary" type="submit" onClick={(e)=>registerRule()}>Create</Button>}
                </div>
              </Form>
                <hr></hr>
          </Card.Body>
        </Card>
        </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">Min Odd</th>
                  <th className="border-0">Min Number of Matches</th>
                  <th className="border-0">Min Stake</th>
                  <th className="border-0">Award Rule</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {betRules.map((pl,index) => <TableRow key={`jackpot-${pl.id}`} {...pl} num={index}/>)}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        
      </> : <Spinner />}
    </>
  );
};
