
import React, { useEffect, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBoxOpen, faChartPie, faCog,faMoneyBill,faStar, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin,faDollarSign,faList, faInbox, faRocket } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
// import { Link,useHistory } from 'react-router-dom';
import { useNavigate,Link,NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RoutesPaths } from "../routes";
import LModel from "../services/api";
import ClientSession from "../services/client-session";
import UserService from "../services/user.service";

import ThemesbergLogo from "../assets/img/themesberg.svg";
// import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ReactHero from "../assets/img/technologies/mulalogo.png";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import avatar from "../assets/img/team/avatar.png";

export default (props = {}) => {
  // const history = useHistory();
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [mainconfigs, setMainConfigs] = useState([]);
  const [logoImg, setLogoImg] = useState("");
  const showClass = show ? "show" : "";

  useEffect(() => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if(isLoggedIn){
        // https://demoapi.convexit.net/admin/
        getCongigurations();
        getUserInfo()
      }
    });
  }, []);
  const getUserInfo=() => {
    LModel.find('client')
      .then(response => {
        if (response.data) {
          let userRoles = [];
          setUserInfo(response.data.user)
          if (response.data.roles && response.data.roles.length) {
            userRoles = response.data.roles.map((role) => {
              return role.role;
            })
          }
          // let items = navigation.items.filter(nav => this.linkItemCanBeShown(nav, response.data.user, userRoles));
          // this.setState({ client: response.data, user: response.data.user, roles: userRoles, navigation: { items } })
        }
      }).catch((e) => {
        if (e && e.status === 400 && e.data.user) {
          let message = Object.keys(e.data.user).map(k => e.data.user[k]).join('\n');
          toast.error(`Error (${e.status}): ${message}`)
        } else if (e && e.status === 400) {
          let message = Object.keys(e.data).map(k => e.data[k]).join('\n');
          toast.error(`Error (${e.status}): ${message}`)
        } else if (e) {
          toast.error(`Error (${e.status}): ${e.statusText}`)
        } else {
          toast.error("Error: Unable to fetch Client!");
        }
        // let items = navigation.items.filter(nav => !nav.role);
        // this.setState({ navigation: { items } })
      });
  };

  const getCongigurations = () => {
    LModel.find("octagon/mainconfigs").then((response) => {
      if (response.data) {
        setMainConfigs(response.data);
        setLogoImg(response.data.logo);
      }
    }).catch(err => {
      console.log(err);
      // setLoading(false);
    });
  }


  const logout = ()=>{
    let loggedOut=UserService.logout();
    // history.push("/Autherization/sign-in/");
    // if(loggedOut)navigate(RoutesPaths.Signin.path);
    navigate(RoutesPaths.Signin.path,{replace:true});
  };

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external,  icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: NavLink, to: link,end:true };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={RoutesPaths.DashboardOverview.path}>
          <div style={{display:'flex'}}><Image src={logoImg} className="navbar-brand-light" /><span style={{marginLeft:8}}>OCTAGON</span></div>
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={avatar} className="user-avatar md-avatar rounded-circle" />
                </div>
                <div className="d-block">
                  <h6>Hi, {userInfo.first_name}</h6>
                  <Button variant="secondary" size="xs" onClick={()=>logout()} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              
              {/* <NavItem title="OCTAGON" link={RoutesPaths.DashboardOverview.path} icon={<Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />} /> */}
              <NavItem title="Configurations" icon={faCog} link={RoutesPaths.Configurations.path} />
              {/* <NavItem title="Event Choices" icon={faList} link={RoutesPaths.Choices.path} /> */}
              {/* <NavItem title="Prices" icon={faDollarSign} link={RoutesPaths.Prices.path} /> */}
              {/* <NavItem title="JackpotConfig" icon={faCog} link={RoutesPaths.JackpotConfig.path} /> */}
              <NavItem title="JackpotList" icon={faList} link={RoutesPaths.JackpotList.path} />
              <NavItem title="TopBets" icon={faStar} link={RoutesPaths.Topbets.path} />
              <NavItem title="Octagon Config" icon={faCog} link={RoutesPaths.OctagonConfig.path} />
              <NavItem title="Payment Methods" icon={faMoneyBill} link={RoutesPaths.PaymentMethods.path} />

              {/* Template Content */}
              {/* <NavItem title="Settings" icon={faCog} link={RoutesPaths.Settings.path} />

              <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                <NavItem title="Bootstrap Table" link={RoutesPaths.BootstrapTables.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="Autherization/" title="Autherization " icon={faFileAlt}>
                <NavItem title="Sign In" link={RoutesPaths.Signin.path} />
                <NavItem title="Sign Up" link={RoutesPaths.Signup.path} />
                <NavItem title="Forgot password" link={RoutesPaths.ForgotPassword.path} />
                <NavItem title="Reset password" link={RoutesPaths.ResetPassword.path} />
                <NavItem title="Lock" link={RoutesPaths.Lock.path} />
                <NavItem title="404 Not Found" link={RoutesPaths.NotFound.path} />
                <NavItem title="500 Server Error" link={RoutesPaths.ServerError.path} />
              </CollapsableNavItem> */}

              {/* <Dropdown.Divider className="my-3 border-indigo" /> */}

            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
