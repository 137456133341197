
import React,{useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
// import { Link ,useHistory} from 'react-router-dom';
import { useNavigate,Link } from 'react-router-dom';

import { RoutesPaths } from "../../routes";
import ClientSession from "../../services/client-session";
import UserService from "../../services/user.service";

import BgImage from "../../assets/img/illustrations/signin.svg";


export default () => {
  const [userName, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  // const history = useHistory();
  let navigate = useNavigate();


  useEffect(() => {
    authUser();

    // return () => {
    //   // componentDidUnmount equivalent
    // };
  }, []);

  const authUser=()=>{
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if(isLoggedIn){
        // Redirect to Home
        console.log("Logged In");
        console.log(authData);
        // setTimeout(()=>{
          // history.push("/");
          return navigate("/",{replace:true});
      // },5000);
      }else{ 
        console.log("Not logged In",authData);
        // login();
      }
    });
  }
  const login =(e)=>{
    e.preventDefault();
    // username : samiadmin
    // password : strong123
    if(password=="" || userName==""){
      // return message.error(local.PhoneandPasswordCannotBeEmpty,5);
      return alert("Phone and Password Cannot Be Empty");
    }else{
      UserService.login(userName, password)
        .then(response => {
          if (response.success) {
            // message.success(response.message);
            // this.setState({isLogedIn:true,visibleLogin:false})
            // this.getProfile();
            // history.push("/");
            navigate("/");
          }
          else {
            // message.error(response.message,5);
            console.log(response);
            alert(response.message);
          }
        })
        .catch(err => {if(!err.response.data){
          // return message.error("Error While Loading Data",5);
          return alert("Error While Loading Data");
        }
          // message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
          alert(err.response.data[Object.keys(err.response.data)[0]][0],5);
        });
    }
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={RoutesPaths.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Row className="justify-content-center form-bg-image" >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to OCTAGON</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>User Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="text" placeholder="User Name" onChange={(e)=>setUsername(e.target.value)} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text >
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)} />
                      </InputGroup>
                    </Form.Group>
                    {/* <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">Lost password?</Card.Link>
                    </div> */}
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100" onClick={(e)=>login(e)}>
                    Sign in
                  </Button>
                </Form>

                {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={RoutesPaths.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
