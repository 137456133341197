
export const RoutesPaths = {
    // pages
    DashboardOverview: { path: "/" },
    Configurations: { path: "/configurations" },
    JackpotConfig: { path: "/JackpotConfig" },
    OctagonConfig: { path: "/OctagonConfig" },
    JackpotConfigId: { path: "/JackpotConfig/:id" },
    JackpotList: { path: "/JackpotList" },
    Choices: { path: "/Choices" },
    ChoicesId: { path: "/Choices/:id" },
    Prices: { path: "/Prices" },
    PricesId: { path: "/Prices/:id" },
    Topbets: { path: "/Topbets" },
    PaymentMethods: { path: "/PaymentMethods" },
    
    Settings: { path: "/settings" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/billing" },
    Invoice: { path: "/invoice" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};