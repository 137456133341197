import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes,useNavigate, Outlet } from 'react-router-dom';

import { RoutesPaths } from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Settings from "./Settings";
import Configurations from "./Configurations";
import JackpotConfig from "./JackpotConfig";
import OctagonConfig from "./OctagonConfig";
import JackpotList from "./JackpotList";
import Choices from "./Choices";
import Prices from "./Prices";
import TopBet from "./TopBet";
import PaymentMethod from "./PaymentMethod";

import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./Autherization/Signin";
import Signup from "./Autherization/Signup";
import ForgotPassword from "./Autherization/ForgotPassword";
import ResetPassword from "./Autherization/ResetPassword";
import Lock from "./Autherization/Lock";
import NotFoundPage from "./Autherization/NotFound";
import ServerError from "./Autherization/ServerError";


// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";


import ClientSession from '../services/client-session';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <> <Preloader show={loaded ? false : true} /> <Component /> </>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={() => auth
        ? children
        : <Navigate to="/login" />
      }
    />
  );
}


const useAuth = () => {
  ClientSession.getAccessToken(function (isLoggedIn, authData) {
    return isLoggedIn;
  });
}
const RequireAuth = ({ children }) => {
  let auth = useAuth();
  // let history = useHistory();

  if (!auth) {
    // return <Navigate to="/login" state={{ from: location }} replace />;
    // history.push("/Authentication/sign-in");
  }
  return children;
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 200);
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        navigate("/sign-in");
      }else console.log("isLoggedIn",isLoggedIn);
    });
    return () => clearTimeout(timer);
  }, []);

  const redirect=(path)=>{
    navigate(path);
  }

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <>
      <Preloader show={loaded ? false : true} />
      <Sidebar redirect={redirect} />

      <main className="content">
        <Navbar redirect={redirect} />
        <Component />
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  );
};


export default () => (
  <>
    <Routes>
      
      <Route path={RoutesPaths.DashboardOverview.path} element={<RouteWithSidebar component={Configurations} />} />
      <Route path={RoutesPaths.Settings.path} element={<RouteWithSidebar component={Settings} />} />
      <Route path={RoutesPaths.Configurations.path} element={<RouteWithSidebar component={Configurations} />} />
      <Route path={RoutesPaths.JackpotConfig.path} element={<RouteWithSidebar component={JackpotConfig} />} />
      <Route path={RoutesPaths.OctagonConfig.path} element={<RouteWithSidebar component={OctagonConfig} />} />
      <Route path={RoutesPaths.JackpotConfigId.path} element={<RouteWithSidebar component={JackpotConfig} />} />
      <Route path={RoutesPaths.JackpotList.path} element={<RouteWithSidebar component={JackpotList} />} />
      <Route path={RoutesPaths.Choices.path} element={<RouteWithSidebar component={Choices} />} />
      <Route path={RoutesPaths.ChoicesId.path} element={<RouteWithSidebar component={Choices} />} />
      <Route path={RoutesPaths.Prices.path} element={<RouteWithSidebar component={Prices} />} />
      <Route path={RoutesPaths.PricesId.path} element={<RouteWithSidebar component={Prices} />} />
      <Route path={RoutesPaths.Topbets.path} element={<RouteWithSidebar component={TopBet} />} />
      <Route path={RoutesPaths.PaymentMethods.path} element={<RouteWithSidebar component={PaymentMethod} />} />

      {/* <Route path={RoutesPaths.BootstrapTables.path} element={<RouteWithSidebar  component={BootstrapTables} /> } /> */}

      <Route path={RoutesPaths.Signup.path} element={<RouteWithLoader component={Signup} />} />
      <Route path={RoutesPaths.ForgotPassword.path} element={<RouteWithLoader component={ForgotPassword} />}/>
      <Route path={RoutesPaths.ResetPassword.path} element={ <RouteWithLoader component={ResetPassword}/>} />
      <Route path={RoutesPaths.Lock.path} element={<Lock />} />
      <Route path={RoutesPaths.NotFound.path} element={<NotFoundPage />} />
      <Route path={RoutesPaths.ServerError.path} element={<ServerError />} />

      <Route path={RoutesPaths.Signin.path} element={<Signin />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </>
);
