import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import { Col, Row, Button, Card, Form,Table, InputGroup,Badge,Nav } from '@themesberg/react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Spin,Select,message,Modal } from 'antd';

import { LoadingOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const { Option } = Select;
const {confirm} = Modal;


export default (props) => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState({});
  const [configOptions, setConfigOptions] = useState("");
  const [jackpotOptions, setJackpotOptions] = useState([]);
  const [eventChoices, setEventChoices] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState("");
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    if(props.id) {
      getJackpotChoices(props.id);
    } 
    // else if(props.id){
    //   getJackpotChoices(props.id);
    // }
    else {
      getEventChoices();
    }
    getCongigOptions();
    getJackpots();
    getMatches("A")
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };
  const getAllEventChoicesConfig = (id) => {
    setLoading(true);
    LModel.find(`octagon/superjackpot/event-choices/${id}`,null,null).then((response) => {
      if (response.data) {
        console.log(response.data);
        setMainConfigs(response.data);
        setSelectedMatch(response.data.event)
        // getAllEventChoicesConfig(id);
        // setEventChoices(response.data.event_choices);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteJackpotChoice = (id) => {
    confirm({
      title: 'Do you want to delete Choice?',
      icon: <ExclamationCircleOutlined />,
      content: `Choice will be Deleted Permanently!`,
      onOk() {
        setLoading(true);
        return new Promise((resolve, reject) => {
          LModel.destroy(`octagon/superjackpot/event-choices`,id).then(response => {
          console.log(response);
          message.success("Choice Removed Successfuly!")
          getEventChoices();
          setLoading(false);
          resolve()
        }).catch(err => {
          console.log(err);
          if(err.response.status==405)
            message.error("Delete Not Allowed");
          setLoading(false);
          reject(err);
        });
      }).catch((err) => console.log('Oops errors!',err));
    },
    onCancel() {},
  });

  }
  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getMatches = (query) => {
    LModel.find(`octagon/matches/${query}/`).then((response) => {
      if (response.data) {
        setMatches(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getJackpots = () => {
    LModel.find("octagon/superjackpot/jackpot/").then((response) => {
      if (response.data) {
        setJackpotOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }
  const getJackpotChoices = (id) => {
    setLoading(true)
    LModel.find(`octagon/superjackpot/jackpot/${id}`).then((response) => {
      // if (response.data) {
      // }
      console.log(response.data);
      setEventChoices(response.data.event_choices);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false)
    });
  }
  const getEventChoices = () => {
    setLoading(true)
    LModel.find(`octagon/superjackpot/event-choices/`).then((response) => {
      if (response) {
        // if(!props.id)
        setEventChoices(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }

  const  createNewChoice =()=>{
      // navigate('/Choices');
      setMainConfigs({});
      setSelectedMatch("");
  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const saveConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    let url=`octagon/superjackpot/event-choices/`;
    if(mainconfigs.id)url=`octagon/superjackpot/event-choices/${mainconfigs.id}/`;
    const data = {...mainconfigs};
    if(props.id)data.jackpot=props.id;
    LModel.update(url, data).then(response => {
      console.log(response);
      if (response.data) {
        setMainConfigs(response.data);
        // getEventChoices();
      }
      setLoading(false);
      getJackpotChoices(props.id);
      props.refreshJackpotConfig();
    }).catch(err => {
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }

  const createConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    let url=`octagon/superjackpot/event-choices/`;
    // if(mainconfigs.id)url=`octagon/superjackpot/event-choices/${mainconfigs.id}/`;
    const data = {...mainconfigs};
    if(props.id)data.jackpot=props.id;
    LModel.create(url, data).then(response => {
      console.log(response);
      if (response.data) {
        setMainConfigs(response.data);
      }
      setLoading(false);
      if(props.id) {
        getJackpotChoices(props.id);
      } 
    }).catch(err => {
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }
  const onConfigChange = (key,value)=>{
    console.log(value);
    const newConfig={...mainconfigs};
    newConfig[key]=value;
    console.log(newConfig);
    setMainConfigs(newConfig);
  } 

const handleMatcheSearch = value => {
    if(value)getMatches(value);
};
const handleMatchChange=(value)=> {
  setSelectedMatch(value);
  onConfigChange('event',value)

}


  const TableRow = (props) => {
    const { id, result, order,event_info, is_reserve, event, jackpot,num } = props;
    console.log(props)

    return (
      <tr>
        <td>
          <Nav.Link href="#" className="text-primary fw-bold">{num}</Nav.Link>
        </td>
        <td className="fw-bold">
          {/* <Nav.Link href="" className="d-flex align-items-center"> */}
            <span >{moment(event_info.schedule).format("YYYY-MM-DD hh:mm")}</span>
          {/* </Nav.Link> */}
        </td>
        <td>{event_info ?<span className="h6"> {event_info.local_team+" - VS - "+event_info.visitor_team}</span> : "--"}</td>
        <td>{order}</td>
        <td>{is_reserve?<Badge bg="success" className="badge-lg">Yes</Badge> :<Badge bg="secondary" className="badge-lg">No</Badge>}
        </td>
        {/* <td>{jackpot ? jackpot : "--"}</td> */}
        <td>
          <Row span={12} >
            <Col md={5} lg={5}>
            <Nav.Link onClick={()=>getAllEventChoicesConfig(id)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faEdit}  /> Edit </div></Nav.Link>
            </Col>
            <Col md={5} lg={5}>
            <Nav.Link onClick={()=>deleteJackpotChoice(id)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faTrash}  /> Delete </div></Nav.Link>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  const OptionsMatches = [];
  for (let i = 0; i < matches.length; i++) {
    const tb = matches[i];
    OptionsMatches.push(<Option key={i} value={tb.pk} label={moment(tb.schedule).format('DD/MM/YYYY')+" | "+tb.local_team + " - VS - " + tb.visitor_team + ""}>{moment(tb.schedule).format('DD/MM/YYYY')+" | "+tb.local_team + " - VS - " + tb.visitor_team + ""}</Option>)
  }
  if(mainconfigs.event_info){
    OptionsMatches.push(<Option key={`event ${mainconfigs.event_info.pk}`} value={mainconfigs.event_info.pk} label={moment(mainconfigs.event_info.schedule).format('DD/MM/YYYY')+" | "+mainconfigs.event_info.local_team + " - VS - " + mainconfigs.event_info.visitor_team + ""}>{moment(mainconfigs.event_info.schedule).format('DD/MM/YYYY')+" | "+mainconfigs.event_info.local_team + " - VS - " + mainconfigs.event_info.visitor_team + ""}</Option>)
  }
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-flex">
          <Button 
          onClick={()=>createNewChoice()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Choice</span>
          </Button>
        </div>
      </div>
      {<>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Choices Configuration</h5>
              <Form>
                <Row>
                  <Col md={4} className="mb-3">
                  <Form.Group id="result" defaultValue={0}>
                      <Form.Label>Result:</Form.Label>
                      <Form.Select id="result" value={mainconfigs.result} onChange={(e) => onConfigChange('result',e.target.value)} >
                        {configOptions.superjackpot_eventchoice_results && configOptions.superjackpot_eventchoice_results.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="order">
                      <Form.Label>Order:</Form.Label>
                      <Form.Control required type="number" defaultValue={mainconfigs.order} onChange={(e) => onConfigChange('order',e.target.value)} />
                    </Form.Group>
                  </Col>
                  {/* <Col lg={4} md={4} className="mb-3">
                    <Form.Group id="reserve" >
                      <Form.Check label="Is Reserved" id="isReserved" htmlFor="checkbox1" checked={mainconfigs.is_reserve} onChange={(e) => onConfigChange('is_reserve',e.target.checked)} />
                    </Form.Group>
                  </Col> */}
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule" defaultValue={0}>
                      <Form.Label>Jackpot:</Form.Label>
                      <Form.Select id="jackpot" value={props.id} disabled={props.id?true:false} onChange={(e) => onConfigChange('jackpot',e.target.value)} >
                        {jackpotOptions && jackpotOptions.map((option, idx) => {
                          return (
                            <option key={idx} value={option.id}>{option.title}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                <Col md={6} className="mb-3">
                    <Form.Group id="order">
                      <Form.Label>Order:</Form.Label>
                      <Form.Control required type="number" defaultValue={mainconfigs.order} onChange={(e) => onConfigChange('order',e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row> */}
                {/* <hr></hr> */}
                <Row>
                  <Col lg={4} md={6} className="mb-3">
                    <Form.Group id="reserve" >
                      <Form.Check label="Is Reserved" id="isReserved" htmlFor="checkbox1" checked={mainconfigs.is_reserve} onChange={(e) => onConfigChange('is_reserve',e.target.checked)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={8} >
                    <Form.Group id="matches" defaultValue={0}>
                      <Form.Label>Event:</Form.Label>
                      <Select
                        showSearch={true}
                        value={selectedMatch}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={(value) => handleMatcheSearch(value)}
                        onChange={(value) => handleMatchChange(value)}
                        notFoundContent={null}
                        style={{ width: '100%' }}
                      >
                        {OptionsMatches}
                      </Select></Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule" defaultValue={0}>
                      <Form.Label>Jackpot:</Form.Label>
                      <Form.Select id="jackpot" value={props.id} disabled={props.id?true:false} onChange={(e) => onConfigChange('jackpot',e.target.value)} >
                        {jackpotOptions && jackpotOptions.map((option, idx) => {
                          return (
                            <option key={idx} value={option.id}>{option.title}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row> */}

                <div className="mt-3">
                  {!mainconfigs.id?<Button variant="primary" type="submit" onClick={(e) => createConfig(e)}>Create</Button>:<Button variant="primary" type="submit" onClick={(e) => saveConfig(e)}>Save</Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row>
        
      </>}
            {!loading ? <>
              <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                  <Table responsive className="table-centered table-nowrap rounded mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Sechedule</th>
                        <th className="border-0">Event</th>
                        <th className="border-0">Order</th>
                        <th className="border-0">Is Reserved</th>
                        {/* <th className="border-0">Jackpot</th> */}
                        <th className="border-0">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventChoices.map((jp,idx) => <TableRow key={`jackpot-${jp.id}`} {...jp}  num={idx+1}/>)}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              
            </> : <Spinner />}
    </>
  );
};
