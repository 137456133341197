import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { Col, Row, Button, Card, Form, InputGroup,Image,Table } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Upload, Spin, Modal,Tabs,message } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit,faList,faDollarSign, faEye,faTrash } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const FormControl= Form.Control;
const { TabPane } = Tabs;

export default () => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState([]);
  const [title, setTitle] = useState("");
  const [firstDepositBonus, setFirstDepositBonus] = useState("");
  const [freebetConfiguration, setFreebetConfiguration] = useState("");
  const [onlineBetPolicyConfig, setOnlineBetPolicyConfig] = useState("");
  const [loyalityPointConfig, setLoyalityPointConfig] = useState("");
  const [promotionDescList, setPromotionDescList] = useState([]);
  const [promotionDescDetail, setPromotionDescDetail] = useState([]);
  const [description, setDescription] = useState("");
  const [configOptions, setConfigOptions] = useState("");
  const [bonusType, setBonusType] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    getMainConfig();
    // getFirstDepositBonus();
    // getFreebetConfiguration();
    // getOnlineBetPolicyConfig();
    // getLoyalityPointConfig();
    getPromotionEndpointLists();
    getCongigOptions();
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };

  const getMainConfig = () => {
    LModel.find("octagon/mainconfigs").then((response) => {
      if (response.data) {
        console.log(response.data);
        setMainConfigs(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const  createNewPromotion =()=>{
    setMainConfigs([]);
    setTitle("");
    setBonusType("");
    setDescription("");
    // setFirstDepositBonus("");
    // setFreebetConfiguration("");
    // setOnlineBetPolicyConfig("");
    // setLoyalityPointConfig("");
    // setPromotionEndpointLists("");
    // navigate('/OcatagonConfig');
  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const createConfig = (e) => {
    e.preventDefault();
    let url="octagon/promotion/description/";
    const promotionDescDetail={bonus_type:bonusType,description:description,title:title};
    LModel.create(url, promotionDescDetail).then(response => {
      console.log(response);
      if (response.data) {
        setMainConfigs(response.data);
        getPromotionEndpointLists();
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }

  const savePromotion = (id) => {
    setLoading(true);
    let url=`octagon/promotion/description/${id}/`;
    const promotionDescDetail={bonus_type:bonusType,description:description,title:title};
    LModel.update(url,promotionDescDetail).then(response=>{
      // console.log(response);
      // if(response.data){
      //   // promotionDescDetail(response.data);
      // }
      getPromotionEndpointLists();
      setLoading(false);
    }).catch(err=>{
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }
  const getPromotionEndpointLists = () => {
    // e.preventDefault();
    setLoading(true);
    LModel.find("octagon/promotion/description/").then((response) => {
      if (response.data) {
        setPromotionDescList(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deletePromotionEndpointLists = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const saveLoyalityPointConfig = (e) => {
    e.preventDefault();
    setLoading(true);
     LModel.update("octagon/loyality-point/config/",mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }
  const getLoyalityPointConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    LModel.find("octagon/loyality-point/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        // setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteLoyalityPointConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const saveOnlineBetPolicyConfig = (e) => {
    e.preventDefault();
    setLoading(true);
     LModel.update("octagon/online-policy/config/",mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }
  const getOnlineBetPolicyConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    LModel.find("octagon/online-policy/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        // setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteOnlineBetPolicyConfig = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const saveFirstDepositBonus = (e) => {
    e.preventDefault();
    setLoading(true);
     LModel.update("octagon/first-deposit-bonus/config/",mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }
  const getFirstDepositBonus = () => {
    // e.preventDefault();
    setLoading(true);
    LModel.find("octagon/first-deposit-bonus/config/").then((response) => {
      if (response.data) {
        console.log(response.data);
        // setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteFirstDepositBonus = (e) => {
    e.preventDefault();
    setLoading(true);
  }

  const saveFreebetConfiguration = (e) => {
    e.preventDefault();
    setLoading(true);
     LModel.update("octagon/mainconfigs/",mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }
  const getFreebetConfiguration = (e) => {
    e.preventDefault();
    setLoading(true);
    LModel.find("octagon/freebet/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        // setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }
  const deleteFreebetConfiguration = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(false);
  }

  const getCongigOptions = () => {
    LModel.find("octagon/options").then((response) => {
      if (response.data) {
        setConfigOptions(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }
  const getPromotionDescriptionDetail = (id) => {
    // setLoading(true);
    // console.log(jackpot)
    LModel.find(`octagon/promotion/description/${id}`,null,null).then((response) => {
      if (response.data) {
        setPromotionDescDetail(response.data);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setBonusType(response.data.bonus_type);
        // params.id && setPrices([response.data]);
        // setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      // setLoading(false);
    });
  }

  const deletePromotionDescriptionDetail = (id) => {

    LModel.destroy(`octagon/promotion/description`,id,null).then((response) => {
      if (response.data) {
        setPromotionDescDetail(response.data);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setBonusType(response.data.bonus_type);
        // params.id && setPrices([response.data]);
        // setLoading(false);
      }
    }).catch(err => {
      console.log(err);
      // setLoading(false);
    });
  }

  const TableRow = (props) => {
    const { id, title, description,bonus_type,num } = props;
    const bonus= configOptions.superjackpot_status && configOptions.superjackpot_status.find((option, idx) =>option[0] == bonus_type);
    return (
      <tr>
        <td>
          <span className="text-primary fw-bold">{num+1}</span>
        </td>
        <td className="fw-bold">
          <Card.Link onClick={()=>getPromotionDescriptionDetail(id)} className="d-flex align-items-center">
            {/* {banner?<Image src={banner} className="image-small rounded-circle me-2" />:""} */}
            <div><span className="h6">{title}</span></div>
          </Card.Link>
        </td>
        {/* <td><Card.Link href={`/JackpotConfig/${id}` } ><div><span className="h6">{description ? description : "--"}</span></div></Card.Link></td> */}
        <td><Card.Link onClick={()=>getPromotionDescriptionDetail(id)} ><div><span className="h6">{bonus ? bonus[1] : "--"}</span></div></Card.Link></td>
        <td>
          <Row className="d-flex align-items-center">
            <Col md={4} lg={4}>
            <Card.Link onClick={()=>getPromotionDescriptionDetail(id)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faEdit}  /> Edit </div></Card.Link>
            </Col>
            <Col md={4} lg={4}>
              <Card.Link onClick={()=>deletePromotionDescriptionDetail(id)} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faTrash} /> Delete </div></Card.Link>
            </Col>
            {/* <Col md={4} lg={4}>
              <Card.Link href={`/Prices/${id}`} className="text-primary fw-bold"><div className="btn btn-light"><FontAwesomeIcon icon={faDollarSign} /> {prizes.length} Prices </div></Card.Link>
            </Col> */}
          </Row>
        </td>
      </tr>
    );
  };

  return (
      <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-flex">
          <Button 
          onClick={()=>createNewPromotion()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Promotion</span>
          </Button>
        </div>
      </div>
      {!loading ? <>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Jackpot Configuration</h5>
              <Form encType="multipart/form">
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="title">
                      <Form.Label>Title:</Form.Label>
                      <Form.Control required type="text" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    {/* <Form.Group id="description">
                      <Form.Label>Description:</Form.Label>
                      <Form.Control as="textarea" placeholder="Description"  value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group> */}
                    <h6>Description:</h6>
                    {params.id ?description&&<CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                        // editor.setData( description );
                        // editor.editing.view.change((writer) => {
                        //   writer.setStyle(
                        //       "height",
                        //       "500px",
                        //       editor.editing.view.document.getRoot()
                        //   );
                        //   });
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />:<CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{height:200}}
                  //   onInit={editor => {
                  //     editor.editing.view.change((writer) => {
                  //       writer.setStyle(
                  //           "height",
                  //           "200px",
                  //           editor.editing.view.document.getRoot()
                  //       );
                  //       });
                  //   }
                  // }
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />}
                  </Col>
                </Row>
                {/* <hr></hr> */}
                
                <Row>
                  <Col lg={4} md={4} >
                    <Form.Group id="cashout_rule" defaultValue={0}>
                      <Form.Label>bonusType:</Form.Label>
                      <Form.Select id="bonusType" value={bonusType} onChange={(e) => setBonusType(e.target.value)} >
                        {configOptions.superjackpot_status && configOptions.superjackpot_status.map((option, idx) => {
                          return (
                            <option key={idx} value={option[0]}>{option[1]}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <div className="mt-3">
                  {promotionDescDetail.id?<Button variant="primary" type="submit" onClick={(e)=>savePromotion(promotionDescDetail.id)}>Save</Button>:<Button variant="primary" type="submit" onClick={(e)=>createConfig(e)}>Create</Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table responsive className="table-centered table-nowrap rounded mb-0">
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">Promotion Title</th>
                  {/* <th className="border-0">Description</th> */}
                  {/* <th className="border-0">Description</th> */}
                  <th className="border-0">Bonus Type</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {promotionDescList.map((pl,index) => <TableRow key={`jackpot-${pl.id}`} {...pl} num={index}/>)}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        
      </> : <Spinner />}

      {/* <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal> */}
    </>
  );
};
