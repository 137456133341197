import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { Col, Row, Button, Card, Form, InputGroup,Image,Table } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Upload, Spin, Modal,Tabs,Switch,message } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit,faList,faDollarSign, faEye } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import { RoutesPaths } from "../routes";

const FormControl= Form.Control;
const { TabPane } = Tabs;

export default () => {
  const params= useParams();
  const [mainconfigs, setMainConfigs] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
    getMainConfig();
  }, []);

  const authUser = () => {
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if (!isLoggedIn) {
        return navigate(RoutesPaths.Signin.path);
      }
    });
  };

  const getMainConfig = () => {
    LModel.find("octagon/loyality-point/config").then((response) => {
      if (response.data) {
        console.log(response.data);
        setMainConfigs(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const  createNewPromotion =()=>{
    setMainConfigs({});
  }

  const Spinner = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>;
  }

  const saveDeposit = (id) => {
    setLoading(true);
    // let url="octagon/first-deposit-bonus/config/";
    let url=`octagon/loyality-point/config/`;
    LModel.update(url,mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5); 
      setLoading(false);
    });
  }
  const createDeposit = () => {
    setLoading(true);
    let url="octagon/loyality-point/config/";
    LModel.create(url,mainconfigs).then(response=>{
      console.log(response);
      if(response.data){
        setMainConfigs(response.data);
        setLoading(false);
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    });
  }

  const onConfigChange = (key,value)=>{
    console.log(value);
    const newConfig={...mainconfigs};
    newConfig[key]=value;
    setMainConfigs(newConfig);
  }

  return (
      <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <div className="d-flex">
          <Button 
          onClick={()=>createNewPromotion()}
          variant="secondary" 
          className="text-dark me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New Promotion</span>
          </Button>
        </div> */}
      </div>
      {!loading ? <>
        <Row> <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Loyality Policy</h5>
              <Form encType="multipart/form">
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="title">
                      <Form.Label>Enable Loyality Program:</Form.Label>
                      <Switch checked={mainconfigs.enable_loyality_program}  onChange={(value)=>onConfigChange('enable_loyality_program',value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={4} className="mb-3">
                    <Form.Group id="title">
                      <Form.Label>Conversion Threshold:</Form.Label>
                      <Form.Control required type="number" value={mainconfigs.conversion_threshold} onChange={(e)=>onConfigChange('conversion_threshold',e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col  md={4} lg={4} className="mb-3">
                    <Form.Group id="min_odd" defaultValue={0}>
                      <Form.Label>Currency to point conversion rate:</Form.Label>
                      <Form.Control required type="number" value={mainconfigs.currency_to_point_conversion_rate} onChange={(e) => onConfigChange('currency_to_point_conversion_rate',e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} className="mb-3">
                    <Form.Group id="min_number_matches" defaultValue={0}>
                      <Form.Label>Point to currency conversion rate:</Form.Label>
                      <Form.Control required type="number" value={mainconfigs.point_to_currency_conversion_rate} onChange={(e) => onConfigChange('point_to_currency_conversion_rate',e.target.value)} />
                    </Form.Group>
                  </Col>

                </Row>
                <hr></hr>
                <div className="mt-3">
                  {<Button variant="primary" type="submit" onClick={(e)=>saveDeposit(mainconfigs.id)}>Save</Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col></Row>
        
      </> : <Spinner />}
      
      {/* <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal> */}
    </>
  );
};
