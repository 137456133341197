import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { Col, Row, Button, Card, Form, InputGroup,Image,Table } from '@themesberg/react-bootstrap';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Upload, Spin, Modal,Tabs } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit,faList,faDollarSign, faEye } from '@fortawesome/free-solid-svg-icons';

import LModel from '../services/api';
import ClientSession from "../services/client-session";

import PromotionDescList from "./PromotionDescList";
import FreeBet from "./FreeBet";
import LoyalityPolicy from "./LoyalityPolicy";
import DepositBonus from "./DepositBonus";
import OnlinePolicy from "./OnlinePolicy";

import { RoutesPaths } from "../routes";

const FormControl= Form.Control;
const { TabPane } = Tabs;

export default () => {
  const params= useParams();
  const [promotion, setPromotion] = useState([]);
  const [loyalityPolicy, setLoyalityPolicy] = useState("");
  const [onlinePolicy, setOnlinePolicy] = useState("");
  const [freeBet, setFreeBet] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    authUser();
  }, []);
  const authUser=()=>{
    ClientSession.getAccessToken(function (isLoggedIn, authData) {
      if(!isLoggedIn){
          return navigate(RoutesPaths.Signin.path);
      }
    });
  };

  return (
      <>
     

      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              Promotion Desc List
            </span>
          }
          key="1"
        >
          <PromotionDescList />
        </TabPane>
        <TabPane
          tab={
            <span>
              Deposit Bonus
            </span>
          }
          key="2"
        >
          <DepositBonus />
        </TabPane>
        <TabPane
          tab={
            <span>
              Free Bet
            </span>
          }
          key="3"
        >
          <FreeBet />
        </TabPane>
        <TabPane
          tab={
            <span>
              Loyality Policy
            </span>
          }
          key="4"
        >
          <LoyalityPolicy />
        </TabPane>
        <TabPane
          tab={
            <span>
              Online Policy
            </span>
          }
          key="5"
        >
          <OnlinePolicy />
        </TabPane>
      </Tabs>
    </>
  );
};
